import React from "react";
import "./coverage.css";
import aa from "../assets/images/coveragepage/Next .png";
import Next from "../assets/images/coveragepage/Next .png";
import thimble from "../assets/images/coveragepage/thimble1.png";
import tend from "../assets/images/coveragepage/TEND.png";
import Arkay from "../assets/images/coveragepage/Arkay .png";
import Coterie from "../assets/images/coveragepage/Coterie .png";
import resourcesEN from "../assets/images/coveragepage/Immigrant Essential Resources Spanish.png";
import resources from "../assets/images/coveragepage/Immigrant Essential Resources.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";


const Coverage = () => {
  return (
    <>
      <h3 className="heading">
        Bind Your Own Policy Online Today -<br></br> See Below For All of Your
        Coverage Options!
      </h3>

      <div
        className="flex justify-center items-center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <p
          href="https://wh-app.io/green-lite-insurance-agency/get-quotes"
          className="heading decoration-2  text-black"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "underline",
            textDecorationThickness: "6px",
          }}
          data-lang="get-quote"
          onClick={(e) => {
            window.location.href = "https://wh-app.io/green-lite-insurance-agency/get-quotes";
          }}
        >
          <Trans>navbar.business_ins</Trans>
        </p>
      </div>

      <div className="cards">
        <div class="card">
          <h3 class="cardHeading">Next</h3>
          <img src={Next} alt="nextLogo" class="logo"></img>
          <ul class="options">
            <li>
              <p class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Commercial
                General Liability
              </p>
            </li>
            <li>
              <p class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Commerical
                Property
              </p>
            </li>
            <li>
              <p class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> E&O
              </p>
            </li>
            <li>
              <p class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Professional
                Liability
              </p>
            </li>
            <li>
              <p class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Commercial
                Umbrella
              </p>
            </li>
            <li>
              <p class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Inland Marine
              </p>
            </li>
          </ul>
          <div className="btnContainer">
            {" "}
            <button
              class="btn"
              onClick={() =>
              (window.location.href =
                "https://track.nextinsurance.com/links?agent_affiliation=PCIotPTUGFyBwbhV&serial=992855993&channel=affiliation")
              }
            >
              Get quote!
            </button>
          </div>
        </div>

        <div class="card">
          <h3 class="cardHeading">Thimble</h3>
          <img src={thimble} alt="nextLogo" class="logo"></img>
          <ul class="options">
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Commercial
                General Liability
              </div>
            </li>
            <li>
              <div class="option optionWithSpan">
                <FontAwesomeIcon icon={faCheck} className="i" /> Business Owners
                Policy
              </div>
            </li>
            <p className="optionSpan">
              <FontAwesomeIcon icon={faAsterisk} className="i" /> Hired + Non
              Owned Auto
            </p>
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Workers Comp
              </div>
            </li>
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Professional
                Liability
              </div>
            </li>
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Commercial
                Cyber
              </div>
            </li>
            <li>
              <div class="option optionWithSpan">
                <FontAwesomeIcon icon={faCheck} className="i" /> Special Event
              </div>
            </li>
            <p className="optionSpan">
              <FontAwesomeIcon icon={faAsterisk} className="i" /> Liquor
              Liability
            </p>
            <br></br>
            <br></br>
            <p className="optionSpan">
              <FontAwesomeIcon icon={faAsterisk} className="i" /> Available In
              Most Zip Codes
            </p>
          </ul>
          <div className="btnContainer">
            {" "}
            <button
              class="btn"
              onClick={() =>
              (window.location.href =
                "https://app.thimble.com/?brokerid=FKFF5P839&utm_campaign=inviter:FKFF5P839&utm_source=broker&utm_medium=referral-link")
              }
            >
              Get quote!
            </button>
          </div>
        </div>
        <div class="card">
          <h3 class="cardHeading">Coterie</h3>
          <img src={Coterie} alt="nextLogo" class="logo"></img>
          <ul class="options">
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Commercial
                General Liability
              </div>
            </li>
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Business Owners
                Policy
              </div>
            </li>
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> E&O
              </div>
            </li>
            <div class="option">
              <FontAwesomeIcon icon={faCheck} className="i" /> Professional
              Liability
            </div>
          </ul>
          <div className="btnContainer">
            {" "}
            <button
              class="btn"
              onClick={() =>
              (window.location.href =
                "https://app.coterieinsurance.com/quote?p=duncan%40greenliteinsurance.com")
              }
            >
              Get quote!
            </button>
          </div>
        </div>
        <div class="card">
          <h3 class="cardHeading">Arkay</h3>
          <img src={Arkay} alt="nextLogo" class="logo"></img>
          <ul class="options">
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Vehicle Service
                Contract
              </div>
            </li>
          </ul>
          <div className="btnContainer">
            {" "}
            <button
              class="btn"
              onClick={() =>
                (window.location.href = "https://arkay.info/FC65449")
              }
            >
              Get quote!
            </button>
          </div>
        </div>
        <div class="card">
          <h3 class="cardHeading">Tend</h3>
          <img src={tend} alt="nextLogo" class="logo"></img>
          <div class="options">
            <li>
              <div class="option">
                <FontAwesomeIcon icon={faCheck} className="i" /> Home Warranty
                Products
              </div>
            </li>
          </div>
          <div className="btnContainer">
            {" "}
            <button
              class="btn"
              onClick={() =>
              (window.location.href =
                "https://partner.mytend.com/first-connect?subproducerID=FC65449")
              }
            >
              Get quote!
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coverage;
