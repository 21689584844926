import React from "react";
import { Trans } from "react-i18next";
import "./styles.css"
const PrivacyPolicyPage = ({ isPage = false }) => {
  return (
    <div
      className={`aboutme  bg-[#f9a602]  bg-repeat flex md:justify-center items-center      `}
    >
      <div className="text-[22px] font-bold mt-5 container mx-auto">
        <div
          className="flex md:w-full flex-col  justify-center text-[#202020] items-center   mainSection_"
          data-lang="aboutcontent"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1
            className="  my-10 text-7xl max-md:text-center"
            data-lang="aboutheader"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Privacy Policy
          </h1>
          <p className="text-pretty text-md font-medium tracking-wide">
            SeguroAmigo.app ("SeguroAmigo", "we", "us," or "our") is committed to protecting your
            privacy. In addition, depending upon where you live, you may have certain data privacy rights
            protected by law in your local jurisdiction. This Privacy Policy explains all the ways in which we
            collect personal information when you visit our website and use our services, including our
            online comparative insurance rating platform (collectively, the "Services"), and your rights as a
            consumer. By visiting our website or using our Services, you ("user," "your" or "you") agree to
            the terms of collection and use of information contained in this Privacy Policy.
          </p>
          <br />
          <h2>What Information Do We Collect?</h2>
          <br />
          <h3 className="">Website Visitors</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            When you visit our website or register for more information,we may
            request that you provide Personal Information about yourself, and we
            also collect- Navigational Information.
          </p>
          <br />

          <h3 className="privacyH3">Personal information</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            Personal Information refers to any information that you provide to
            us and that identifies you personally, including contact
            information, such as your name, e-mail address, company name,
            address, phone number, and other information about yourself or your
            business. It can also include information about any transactions,
            both free and paid, that you enter into on the Website, and
            information about you that is available on the internet, such as
            from Facebook, LinkedIn, TikTok, Twitter, and Google, or information
            that we acquire from service providers. Personal Information
            includes Navigational Information where such information can
            directly or indirectly identify you.
          </p>

          <h3 className="privacyH3">Information we collect from third Parties</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            From time to time, we may receive Personal Information about you
            from third-party sources including partners with which we offer
            co-branded services or engage in joint marketing activities, and
            other sources such as social media websites and search engines.
          </p>

          <h3 className="privacyH3">Navigational Information</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            We may collect web tracking technologies such as cookies, web
            beacons, pixel tags, and clear GIFs in order to operate the website
            efficiently and to collect data related to your usage of and
            interaction with the Service. Such collected data ("Navigational
            Information") may include the address of the services you visited
            before and after you visited the Service, your geographic location,
            the search terms you used, the date and time of your visit, the type
            of browser you are using, your Internet Protocol (IP) address, what
            pages in the Service you visit and links you clicked on, and whether
            you opened email communications we send to you. If you use a mobile
            device to access our Service, we may also collect information about
            the geographical location from which you accessed the Service.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            In order to collect the web tracking Information and to make your use of
            the Service more efficient, we may store cookies on your computer.
            These cookies will be stored (1) until you close the browser, (2)
            until you log off the Service, or (3) until they expire. For more
            information about cookies generally, please visit{" "}
            <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            We may also use web tracking technologies that are placed in
            webpages on the Service or in email communications to collect
            information about actions that you take when you interact with the
            Service or our email communications. Navigational Information may
            include data, such as IP address data, that is unique to you. Many
            of the products we offer, such as insurance products, are specific
            to your location. You may be able to modify your browser settings to
            alter which web tracking technologies are permitted when you use the
            Service, but this may affect the performance of the Service.
            Likewise, most mobile devices allow you to disable the ability for
            geo-location to be collected from your mobile device, but this may
            also affect the performance of the Service.
          </p>

          <h3 className="privacyH3">Log Files</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            When you view content provided by us, we automatically collect
            information about your computer hardware and software. This
            information can include your IP address, browser type, domain names,
            internet service provider (ISP), the files viewed on our site (e.g.,
            HTML pages, graphics, etc.), operating system, clickstream data,
            access times and referring website addresses. We use this
            information to provide general statistics regarding use of the
            Websites. For these purposes, we do link this
            automatically-collected data to other Personal Information such as
            name, email address, address, and phone number.
          </p>

          <h2>How We Use Information We Collect</h2>
          <h3 className="privacyH3">Personal Information</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            We will use and store Personal Information for the purpose of
            delivering the Service and to analyze and enhance the operation of
            the Service.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            We may use the Personal Information you provide or that we collect
            about you, as well as non-personally identifiable information, to
            fulfill your requests for our products, programs and services, to
            respond to your inquiries about our offerings, and to offer you
            other products, programs or services that we believe may be of
            interest to you. We sometimes use this information to communicate
            with you.
          </p>

          <h3 className="privacyH3">SMS/Text Messaging Policy</h3>
          <ol>
            <li>
              <strong className="strong">1. Introduction</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                This section outlines our practices related to the collection, use, and management of mobile phone 
numbers provided by consumers for SMS/text messaging communication. This policy applies 
globally, unless otherwise specified, and is tailored to meet the needs of our diverse user base
              </p>
            </li>
            <li>
              <strong className="strong">2. Collection of Mobile Phone Numbers</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
               Method of Collection: We collect mobile phone numbers directly from consumers when they 
voluntarily provide them through our online platform or in person, expressly for the purpose of 
receiving SMS/text messages related to our services.
              </p>
              <p className="text-pretty text-md font-medium tracking-wide">
                Purpose of Collection: Mobile phone numbers are collected specifically for insurance quotes, 
policy updates, customer service interactions
              </p>
            </li>
            <li>
              <strong className="strong">3. Consent for SMS/Text Messaging</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                Explicit Consent: By entering their mobile phone number and clicking “Continue” on their profile 
setup, consumers explicitly consent to receive SMS/text messages from us for the purposes stated. 
This consent mechanism is visually represented as follows: 
              </p>
              <p className="text-pretty text-md font-medium tracking-wide">
                Mechanism of Consent: Consent is obtained when a consumer inputs
                their phone number and affirmatively indicates their agreement
                by clicking “Continue” once the consumer has input their email
                and phone number, in order to complete their profile and receive
                insurance quotes.
              </p>
            </li>
            <li>
              <strong className="strong">4. Nature of SMS/Text Messages</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                Content: Messages may include details on insurance quotes, policy updates, reminders, and other 
relevant information.
Frequency: Users can expect to receive messages at a frequency depending on their preferences 
and interactions with our services, generally not exceeding one message per week unless 
requested.
No mobile information will be shared with third parties/affiliates for marketing/promotional 
purposes. All other categories exclude text messaging originator opt-in data and consent; this 
information will not be shared with any third parties
              </p>
            </li>
            <li>
              <strong className="strong">5. Opt-out Policy</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
               Easy Opt-out: Users may discontinue receiving SMS/text messages at any time by following the 
simple opt-out instructions provided in each message or by contacting us via alternative methods 
listed below.
              </p>
              <p className="text-pretty text-md font-medium tracking-wide">
                Opt-out Consequences: After opting out, users will receive only essential transactional messages, 
such as opt-out confirmations
              </p>
            </li>
            <li>
              <strong className="strong">6. Data Security and Privacy</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                Protection of Mobile Numbers: We commit to the security of consumer mobile phone numbers, 
which are not shared with third parties without explicit consent, except as required under 
applicable law
              </p>
              <p className="text-pretty text-md font-medium tracking-wide">
                Use of Data: Collected data will be used strictly for the purposes outlined in this policy, adhering to 
our comprehensive data security standards
              </p>
            </li>
            <li>
              <strong className="strong">7. Compliance with Laws</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                Our practices are designed to comply with the Telephone Consumer Protection Act (TCPA) and 
other relevant telecommunications and privacy regulations, ensuring the responsible management 
of user data and adherence to consent requirements
              </p>
            </li>
            <li>
              <strong className="strong">8. Amendments to the SMS/Text Messaging Policy</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                Policy Updates: We reserve the right to modify this SMS/Text
                Messaging Policy at any time. Any changes will be reflected on
                our website with an updated revision date.
              </p>
            </li>
            <li>
              <strong className="strong">9. Contact Information</strong>
              <p className="text-pretty text-md font-medium tracking-wide">
                For questions or concerns regarding this policy or your SMS/text messaging preferences, please 
reach out to us via:
- Email: sms@seguroamigo.app
- Phone: (877) 630-0487
- Mailing Address: 2615 E End Blvd S, Suite 240, Marshall, TX 75672
Accessibility: This policy is available in multiple languages and accessible formats upon request to 
accommodate all users

User Rights: Users have the right to access, correct, and request deletion of their personal data. 
Please contact us at the details provided above to exercise these rights
              </p>
            </li>
          </ol>

          <h3 className="privacyH3">Navigational Information</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            We use web tracking Information to administer the website and to
            understand how well our website is working, to store your user
            preferences, to develop statistical information on usage of the
            website, and to analyze the productivity of users of the website.
            This allows us to determine which features visitors like best to
            help us improve our website, to personalize your user experience,
            and to measure overall effectiveness.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            You may disable the collection and use of your location data through
            browser-, operating system-, or device-level settings. Consent
            concerning location data may be withdrawn at any time by emailing
            privacy@SeguroAmigo.com.
          </p>

          <h3 className="privacyH3">Targeted Advertising</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            SeguroAmigo may serve you targeted advertising. This may include
            using information about your visits to this and other websites in
            order to provide relevant advertisements about goods and services
            that you may be interested in. We may also employ technology that is
            used to measure the effectiveness of advertisements. This can be
            accomplished by using cookies or web beacons to collect information
            about your visits to this and other sites in order to provide
            relevant advertisements about goods and services of potential
            interest to you.
          </p>

          <h3 className="privacyH3">Information We Disclose to Third Parties</h3>
          <h4 className="privacyH4">Personal Information</h4>
          <p className="text-pretty text-md font-medium tracking-wide">
            We will not disclose your Personal Information to any third parties
            except as follows:
          </p>
          <ul>
            <li>
              to third party contractors engaged to provide services on our
              behalf ("Contractors"), such as analyzing data and usage of the
              Service, operating the Service or providing support and
              maintenance services for the Service, performing marketing
              support, or providing customer service;
            </li>
            <li>
              to insurance carriers and other providers in whose products you
              express interest; and
            </li>
            <li>when we have your consent to share the information.</li>
          </ul>

          <h4 className="privacyH4">Web Tracking Information</h4>
          <p className="text-pretty text-md font-medium tracking-wide">
            We disclose web tracking information to Contractors, in order to
            analyze the performance of the Services and the behavior of users,
            and to operate and improve the Services. This web tracking
            information may be shared with Contractors in aggregated form or in
            conjunction with your Personal Information in order to improve the
            Services for you.
          </p>

          <h3 className="privacyH3">Additional Disclosures</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            We reserve the right to disclose any information we collect in
            connection with the Services, including Personal Information to any
            successor to our business as a result of any merger, acquisition,
            asset sale or similar transaction.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            We will also share your information with law enforcement agencies,
            public authorities or other organizations if legally required to do
            so, or if we have a good faith belief that such use is reasonably
            necessary and in our legitimate interests to:
          </p>
          <ul>
            <li>comply with a legal obligation, process or request;</li>
            <li>
              enforce our Terms of Service and other agreements, including
              investigation of any potential violation thereof;
            </li>
            <li>
              detect, prevent or otherwise address security, fraud or technical
              issues; or
            </li>
            <li>
              protect the rights, property or safety of us, our users, a third
              party or the public as required permitted by law (i.e., exchanging
              information with other companies and organizations for the
              purposes of fraud protection);
            </li>
            <li>
              pursuant to a lawful request from law enforcement or a legal
              authority; or
            </li>
            <li>in an acquisition or merger.</li>
          </ul>

          <h3 className="privacyH3">Cookies</h3>
          <h4 className="privacyH4">What are Cookies</h4>
          <p className="text-pretty text-md font-medium tracking-wide">
            A cookie is a small file containing a string of characters that is
            sent to your computer when you visit a website. When you visit the
            site again, the cookie allows that site to recognize your browser.
            Cookies may store user preferences and other information.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            Cookies set by SeguroAmigo are called "first-party cookies". Cookies
            set by parties other than us are called "third-party cookies".
            Third-party cookies enable third-party features or functionality to
            be provided on or through the website (e.g., like advertising,
            interactive content and analytics). The parties that set these
            third-party cookies can recognize your computer both when it visits
            the website in question and also when it visits certain other
            websites.
          </p>

          <h4 className="privacyH4">Why We Use Cookies</h4>
          <p className="text-pretty text-md font-medium tracking-wide">
            We use first-party and third-party cookies for several reasons. Some
            cookies are required for technical reasons in order for our Website
            to operate, and we refer to these as "essential" or "strictly
            necessary" cookies. Other cookies also enable us to track and target
            the interests of our users to enhance the experience on our Website.
            For example, SeguroAmigo keeps track of the Website and pages you
            visit within SeguroAmigo, in order to determine what portion of the
            SeguroAmigo Website is the most popular or most used. This data is
            used to deliver customized content and promotions within the
            SeguroAmigo Website to customers whose behavior indicates that they
            are interested in a particular subject area. Third parties serve
            cookies through our Website for advertising, analytics and other
            purposes. This is described in more detail below.
          </p>

          <h4 className="privacyH4">Types Of Cookies We Use and How We Use Them</h4>
          <p className="text-pretty text-md font-medium tracking-wide">
            The specific types of cookies served through our Website and the
            purposes they perform include:
          </p>
          <ul>
            <li>
              Essential website cookies: These cookies are strictly necessary to
              provide you with services available through our Website. Because
              these cookies are strictly necessary to deliver the Website to
              you, you cannot refuse them. You can block or delete them by
              changing your browser settings however, as described below.
            </li>
            <li>
              Performance and functionality cookies: These cookies are used to
              enhance the performance and functionality of our Website but are
              non-essential to their use. However, without these cookies,
              certain functionality may become unavailable.
            </li>
            <li>
              Analytics and customization cookies: These cookies collect
              information that is used either in aggregate form to help us
              understand how our Website is being used or how effective our
              marketing campaigns are, or to help us customize our Website for
              you.
            </li>
            <li>
              Advertising cookies: These cookies are used to make advertising
              messages more relevant to you. They perform functions like
              preventing the same ad from continuously reappearing, ensuring
              that ads are properly displayed for advertisers, and in some cases
              selecting advertisements that are based on your interests.
            </li>
            <li>
              Social networking cookies: These cookies are used to enable you to
              share pages and content that you find interesting on our Website
              through third-party social networking and other websites. These
              cookies may also be used for advertising purposes too.
            </li>
          </ul>

          <h4 className="privacyH4">How Can You Control Cookies?</h4>
          <p className="text-pretty text-md font-medium tracking-wide">
            You have the right to decide whether to accept or reject cookies.
            You can exercise your cookie preferences by clicking on the
            appropriate opt-out links provided below. You can set or amend your
            web browser controls to accept or refuse cookies. If you choose to
            reject cookies, you may still use our website though your access to
            some functionality and areas of our website may be restricted.
          </p>

          <p className="text-pretty text-md font-medium tracking-wide">
            As the means by which you can refuse cookies through your web
            browser controls vary from browser-to-browser, you should visit your
            browser's help menu for more information. Most advertising networks
            also offer you a way to opt out of targeted advertising. If you
            would like to find out more information, please visit
            http://www.aboutads.info/choices/ or
            http://www.youronlinechoices.com. You may opt out by clicking here:
            http://preferences.truste.com/. Please note this does not opt you
            out of being served advertising. You will continue to receive
            generic advertisements.
          </p>

          <h3 className="privacyH3">Other Tracking Technologies, Like Web Beacons</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            Cookies are not the only way to recognize or track visitors to a
            website. We employ a software technology called clear gifs (a.k.a.
            Web Beacons/Web Bugs), that help us better manage the Website and
            Subscription Service by informing us what content is effective.
            Clear gifs are tiny graphics with a unique identifier, similar in
            function to cookies, and are used to track the online movements of
            Web users. In contrast to cookies, which are stored on a user's
            computer hard drive, clear gifs are embedded invisibly on Web pages
            or in emails and are about the size of the period at the end of this
            sentence. We use clear gifs or pixels in our HTML-based emails to
            let us know which emails have been opened by recipients. This allows
            us to gauge the effectiveness of certain communications and the
            effectiveness of our marketing campaigns. We tie the information
            gathered by clear gifs in emails to our customers' Personal
            Information.
          </p>
          <h3 className="privacyH3">For California Residents</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            The California Consumer Privacy Act ("CCPA") went into effect on
            January 1, 2020. The law gives California residents certain rights
            to access and delete their "Personal Information," and to opt out of
            the sale of their Personal Information. The CCPA also requires
            businesses to disclose in their privacy policies information about
            how they collect, use, and share Personal Information. This section
            of the privacy policy contains the disclosures SeguroAmigo is
            required to make and summarizes the rights that California residents
            have under the CCPA. For purposes of this section, the term
            "Personal Information" means Personal Information under Cal. Civ.
            Code 1798.140(o) as amended from time to time. To the extent that
            any other provision of this Privacy Policy conflicts with a
            provision of this section, the provision in this section controls as
            to California residents.
          </p>
          <h3 className="privacyH3">Summary of California Residents' CCPA Rights</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            California residents may: <br /> • Request that we delete their Personal
            Information (a "Request to Delete"). <br /> • Opt-out of the sale of their
            Personal Information (a "Request to Opt-Out"). <br /> • Request information
            about our collection, use, and sharing of their information (a
            "Request to Know"), including: <br /> 1. the categories and specific pieces
            of Personal Information we have collected about them; <br /> 2. the
            categories of sources from which we have collected their Personal
            Information; <br /> 3. the business or commercial purpose for collecting or
            selling their Personal Information; <br /> 4. the categories of their
            Personal Information that we have sold; and <br /> 5. the categories of
            third parties with whom we have shared, disclosed for a business
            purpose, or sold their Personal Information, and which categories of
            Personal Information we have sold to which categories of third
            parties.<br />  We will not discriminate against users for exercising these
            rights. We may charge users different prices, or provide different
            levels or quality of goods or services, if the difference is
            reasonably related to the value of their data. We may also offer
            financial incentives for the collection, sale, or deletion of your
            Personal Information. In addition, some Personal Information is
            essential for us to provide the Services. For example, we cannot
            provide you with an insurance quote without first collecting some
            Personal Information about you. In such instances, your refusal to
            provide the necessary Personal Information will result in the
            inability to use our Service.
          </p>
          <h3 className="privacyH3">Submitting Requests to Know, Opt-Out, or Delete Your Data</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            California residents may submit a Request to Know, Opt-Out, or
            Delete Your data by filling out the form found at: Do Not Sell My
            Data, by email at: privacy@SeguroAmigo.com. We may require you to
            confirm your identity before we share any Personal Information or
            honor an Opt-Out or Deletion request. If SeguroAmigo is unable to
            verify your identity, SeguroAmigo will respond by explaining why it
            cannot verify your identity. We will confirm receipt of your Request
            to Know within 10 business days and will respond to your request
            within 45 days. If a response requires additional time, we will
            notify you of the basis for the delay and may extend our response
            period up to an additional 45 days. We have no obligation to provide
            Personal Information to you more than twice in a 12-month period. If
            a request or series of requests are manifestly unfounded or
            excessive, we may charge a reasonable fee for processing the
            request(s), or may refuse to process the request(s). If SeguroAmigo
            is able to verify your identity, we will respond to your request by
            (a) honoring your request; or (b) where applicable, explaining why
            the CCPA does not require us to honor your Personal Information.
            SeguroAmigo may choose to delete Personal Information by
            de-identifying, aggregating, or completely erasing the information.
          </p>
          <h3 className="privacyH3">Authorized Agents</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            California residents may submit Requests to Know, Opt-Out, or Delete
            through an authorized agent. Unless the agent provides SeguroAmigo
            with a power of attorney executed by the California resident,
            SeguroAmigo may require the California resident to verify their
            identity with SeguroAmigo and/or to provide SeguroAmigo with written
            confirmation of the agent's authority to make the request.
          </p>
          <h3 className="privacyH3">California Online Privacy Protection Act Notice</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            On September 27, 2013, California enacted A.B. 370, amending the
            California Online Privacy Protection Act to require website
            operators like SeguroAmigo to disclose how they respond to "Do Not
            Track Signals"; and whether third parties collect personally
            identifiable information about users when they visit SeguroAmigo. •
            SeguroAmigo does not respond to "do not track" signals. •
            SeguroAmigo does not authorize the collection of personally
            identifiable information from SeguroAmigo users for third party use
            through advertising technologies without separate member consent.
          </p>
          <h3 className="privacyH3">Security</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            We have put in place appropriate physical, electronic, and
            managerial procedures and security precautions to protect the
            security and integrity of your Personal Information in accordance
            with this policy and applicable law. However, no Internet
            transmission is completely secure, and we cannot guarantee that
            security breaches will not occur. Without limitation of the
            foregoing, we are not responsible for the actions of hackers and
            other unauthorized third parties that breach our reasonable security
            procedures.
          </p>
          <h3 className="privacyH3">Amendments</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            SeguroAmigo may modify or amend this policy from time to time. If we
            make any material changes, as determined by SeguroAmigo, in the way
            in which Personal Information or web tracking Information is
            collected, used or transferred, we will notify you of these changes
            by modification of this Privacy Policy, which will be available for
            review by you at the website. Notwithstanding any modifications we
            may make, any Personal Information and web tracking Information
            collected by SeguroAmigo from you will be treated in accordance with
            the privacy policy in effect at the time information was collected,
            unless we obtain your consent otherwise.
          </p>
          <h3 className="privacyH3">Accessibility</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            Any person with a disability that prevents or restricts them from
            accessing this Privacy Policy through the Apps or through Seguro
            Amigo App's website may request a copy of the Privacy Policy in an
            alternative format by contacting SeguroAmigo at:
            privacy@SeguroAmigo.com.
          </p>
          <h3 className="privacyH3">Children</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            SeguroAmigo does not knowingly collect or maintain personally
            identifiable information from persons under 16 years of age, and no
            part of the website is directed at persons under 16. If you are
            under 16 years of age, then please do not use the website. The CCPA
            and Children's Online Privacy Protection Act imposes certain
            requirements on website operators that have actual knowledge that
            they collect personally identifiable information from children under
            13 years of age. If SeguroAmigo learns that personally identifiable
            information of persons less than 13 years of age has been collected
            without verifiable parental consent, then SeguroAmigo will take the
            appropriate steps to delete this information. To make such a
            request, or if you have any questions or concerns about the Privacy
            Policy for the website or its implementation, please contact us at:
            privacy@seguroamigo.app.
          </p>
          <h3 className="privacyH3">Visitors From Outside the United States</h3>
          <p className="text-pretty text-md font-medium tracking-wide">
            SeguroAmigo and its servers are located in the United States and are
            subject to the applicable state and federal laws of the United
            States. Those who choose to access the Service do so on their own
            initiative and at their own risk, and are responsible for complying
            with all local laws, rules and regulations. We may limit the
            Service's availability, in whole or in part, to any person,
            geographic area or jurisdiction we choose, at any time and in our
            sole discretion. We do not represent or warrant that the Service, or
            any part thereof, is appropriate or available for use in any other
            jurisdiction. If you choose to access or use the Service, you
            consent to the use and disclosure of information in accordance with
            this privacy policy and subject to such laws. seguroamigo.app and
            m.seguroamigo.app are owned by DBB Capital Ventures LLC. DBB Capital
            Ventures LLC can be reached by emailing info@dbbcapitalventures.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
