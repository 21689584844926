export const seguroData = [
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186647396617310318/image.png?ex=65940282&is=65818d82&hm=17e168f3c01163f86eb74debae7220a3596c41e69d274044ac07c0caa70d7e60&",
    claim: "(866) 424-6726",
    header: "Gainsco",
    link: "https://www.gainsco.com/",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186648699489759292/image.png?ex=659403b8&is=65818eb8&hm=47ac6e4efd8185f687bf1d7a7a783b3698d0ddcb073e407b57d9b6c270251e1c&",
    claim: "(888) 842-5494",
    header: "Ignition",
    link: "https://www.ignitioninsurance.com/",
  },
  {
    icon: "https://media.discordapp.net/attachments/1138016068359688203/1181249984469925898/image.png?ex=6592d4c6&is=65805fc6&hm=185535eff672ef24e53026a6a04584462a4051b229505ffc695a88c35a168b8f&format=webp&quality=lossless&width=391&height=151&",
    claim: "(800) 422-0792",
    header: "Mendota",
    link: "https://www.mendota-insurance.com/PublicSite/ContactUs.aspx",
  },
  {
    icon: "https://media.discordapp.net/attachments/1138016068359688203/1181256347191361616/image.png?ex=6592dab3&is=658065b3&hm=ee0525d67e5a172bf4b237aa348c338b9a33972b55c83d0368133e2230505a03&format=webp&quality=lossless&width=391&height=151&",
    claim: "(877) 220-6130",
    header: "SeaHarbour",
    link: "https://www.seaharborinsurance.com/contact.aspx",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653865710456852/image.png?ex=65940888&is=65819388&hm=44b57ff43f66583c31b620025e04956ed0b80541c3d7f2218e9818b80b45a6d6&",
    claim: "(773) 458-1055",
    header: "StoneGate",
    link: "https://www.stonegateins.com/submit-a-claim-personal-auto",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653885167829102/image.png?ex=6594088d&is=6581938d&hm=c309d833b9f1f02f156b37627a8b3b246651b7dc43bd749b7638023d30c47d8f&",
    claim: "(844) 421-8813",
    header: "Trinity",
    link: "https://www.tttmga.com/customers/",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653899906625536/image.png?ex=65940890&is=65819390&hm=4483004a983adca67bab514c717291ff0a96c20a8db4550c5f8b18b2db538f56&",
    claim: "(877) 437-5007",
    header: "Alinsco",
    link: "https://www.alinsco.com/file-a-claim",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653912858640504/image.png?ex=65940893&is=65819393&hm=49727c6c7cd2923475228f983cbd02d1fa4b81d7f4e5feadab554e0f0b25f8ed&",
    claim: "(866) 203-3099",
    header: "AmWins",
    link: "https://www.amwinsauto.com/report-a-claim",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653955535671311/image.png?ex=6594089d&is=6581939d&hm=ac97d85f267e3aef8d97e64cff9142201a20f720908dcccdfe58f46b440adecc&",
    claim: "(800) 450-7857",
    header: "AssuranceAmerica",
    link: "https://www.assuranceamerica.com/Contact",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653975953543218/image.png?ex=659408a2&is=658193a2&hm=56ba3ff05ecf787c7b86eab5391dc9ca764e47736a025d277ba674da6a070c19&",
    claim: "(866) 424-9514",
    header: "Bluefire",
    link: "https://www.bluefireinsurance.com/form-claims/",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186653989668921454/image.png?ex=659408a6&is=658193a6&hm=c743a2f585924b4ef28366dfe0af9c9d1df8de9c11e2c57cd30fcc04c208379c&",
    claim: "(800) 274-7865",
    header: "Bristol West",
    link: "https://www.bristolwest.com/home/claims/",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186658787302584350/6002a40f21b928e290e07757_main-logo-2_clean.png?ex=65940d1d&is=6581981d&hm=f0913c5c9b76b0e32a666d760191a00c7272499b15a065861fb1523eb4ade134&",
    claim: "(877) 603-1310",
    header: "Commonwealth",
    link: "https://www.commonwealthcasualty.com/claims",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186654000314060811/image.png?ex=659408a8&is=658193a8&hm=1abbd7c207ee116d9aa2674d6a62f906e1437052b129f6474814f47d65db47b0&",
    claim: "(877) 218-7865",
    header: "Elephant",
    link: "https://www.elephant.com/customers/claims",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186654014859903046/image.png?ex=659408ac&is=658193ac&hm=4683f041d42c23fb67bf31e9fd3e7b0fa83af68a255631c3f83d5bf89df3d5d2&",
    claim: "(833) 362-2751",
    header: "Embark",
    link: "https://www.embarkmga.com/claims",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186659069948330045/logo_03.png?ex=65940d61&is=65819861&hm=9ac86b5f775d704643b931956ff94de32fe1f0c8904971ef8270ce6ffdc6aa60&",
    claim: "(866) 386-7475",
    header: "Evolution Risk",
    link: "https://www.ermga.com/",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186659399595474964/BannerClient29.png?ex=65940daf&is=658198af&hm=59061e6fc8c581a390bbbe622182597967268720f4c462b8bb914ce6ce3a9952&",
    claim: "(877) 437-6264",
    header: "Excellent",
    link: "https://www.excellentins.com/claims",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186659535998427256/Fenix_ID_2C.png?ex=65940dd0&is=658198d0&hm=faf7d7e49f1f8f8a515f7f87b681a6746257ab55013a8a80267e2f61c5025b5c&",
    claim: "(440) 922-5222",
    header: "Fenix",
    link: "https://www.fenixga.com/",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186660064103251968/image.png?ex=65940e4e&is=6581994e&hm=73d15d8f2982d7b44d6c3159b412f59cea4173d8c3ddde758cd90f52df9e01b5&",
    claim: "(800) 929-3252",
    header: "Falcon",
    link: "https://www.falconinsgroup.com/en/Claims/ReportClaim",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186654032085913620/IMG-20231205-WA0006.png?ex=659408b0&is=658193b0&hm=f2a3c1eea186291625ffc799a4747d919318f7d23b73fd7f7bc29c1b27da0a88&",
    claim: "(855) 935-1233",
    header: "Quantum",
    link: "https://www.quantummga.com/file-a-claim/default.aspx",
  },
  {
    icon: "https://cdn.discordapp.com/attachments/1080886691067338815/1186658210246041640/DIZpFqEskAAAAASUVORK5CYII.png?ex=65940c94&is=65819794&hm=ebd19484ddc99a5bc48e273ab80076b8bbecc0c78341186989043366ff572e33&",
    claim: "(855) 935-1233",
    header: "MileAuto",
    link: "https://www.mileauto.com/contact-us",
  },
];
