import React, { useEffect, useRef, useState } from "react";
import jQuery from 'jquery';
import { Trans, useTranslation } from "react-i18next";
export const ContactUs = ({ isPage = false }) => {
  const sectionRef = useRef(null);
  // eslint-disable-next-line
  const { t, i18n } = useTranslation("global");
  const [showMore, setShowMore] = useState(false);

  const toggleReadMore = async () => {
    setShowMore(!showMore);
  };


  // const morePara = <Trans>home.paragraphs1.p3</Trans>;

  // Apoyo Empresarial con Seguros Comerciales: No solo contamos con Seguros para autos, Seguro Amigo
  // App ofrece opciones completas para seguros comerciales. Sabemos de primera mano que muchos
  // inmigrantes que llegan a América también son exitosos propietarios de pequeñas empresas.
  // Entendemos los diferentes matices de iniciar y administrar un negocio, nuestra plataforma está
  // diseñada para brindar la protección que tu empresa merece. Continuaremos agregando más valor a esta
  // plataforma en cada oportunidad que tengamos que hacerlo. También escucharemos las necesidades de
  // nuestros clientes. Si no tenemos lo que TÚ necesitas, haremos todo lo posible por traerlo aquí y
  // brindártelo.
  // Más que Solo Seguros: Más allá de encontrar la póliza correcta, Seguro Amigo App sirve como un centro
  // de recursos. Proporcionamos enlaces e información valiosa para ayudarte en varios aspectos de tu vida,
  // desde asesoría legal hasta planificación financiera. Nuestra misión es hacer de esta plataforma una
  // comunidad que te ofrezca tanto apoyo como sea posible. Continuaremos agregando valor cada día.
  // Con Seguro Amigo App, eliges un AMIGO comprometido a proporcionarte las mejores soluciones de
  // seguros. Te invitamos a unirte a nuestra plataforma y experimentar un enfoque sin complicaciones para
  // encontrar el mejor seguro para tu auto.

  useEffect(() => {
    executeScroll();
    // window.addEventListener("scroll", reveal);
    // return () => {
    //   window.removeEventListener("scroll", reveal);
    // };
  }, []);
  const executeScroll = () => {
    isPage && sectionRef.current.scrollIntoView();
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowheight = window.innerHeight;
      var revealtop = reveals[i].getBoundingClientRect().top;
      var revealpoint = 110;

      if (revealtop < windowheight - revealpoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }



  const [emaildiabled, setemaildiabled] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Function to handle changes in input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    let name = formData.name;
    let emailid = formData.email;
    let msg = formData.message;

    if (emailid !== '' && name !== '' && msg !== '') {

      setemaildiabled(true);
      document.getElementById('sendmsg').style.backgroundColor = 'gray';

      jQuery.ajax({
        url: 'https://script.google.com/macros/s/AKfycbyP6x7RrQ8gkqI7iFGPdpwEB-Bd65obeBZS1viJbtp9YcdWOIswyw8KnUj83nnp4ATS/exec',
        // url: 'https://script.google.com/macros/s/AKfycbyUCmLsMK0rSABAQdmUgToZblWfAOHozL9QLv3SKZkx6viEzJwlDzwbL6LLn4WEdlv9/exec',
        type: 'post',
        data: { 'name': name, 'emailid': emailid, 'msg': msg }, // Use an object with a key 'zipcode'

        success: function (r) {
          // Handle success if needed
          // console.log("r = " + r);

          if (r === name) {
            alert('We have recorded your Request Successfully');

            console.log('Form submitted:', formData);
            // You can also reset the form after submission
            setFormData({ name: '', email: '', message: '' });

          }
        },

      });

    }

    else {
      alert('Please enter All Details');
    }
  }



  return (
    <div
      ref={sectionRef}
      className={`aboutme  bg-[url('assets/images/bg-about-us.png')] md:bg-cover bg-repeat flex md:justify-center items-center
      ${showMore ? "md:h-[1200px] md:bg-none bg-[#ffa800] mt-0" : "h-full"}
      `}
      id="about"
    >









      <div className="text-[22px] font-bold mt-5 container mx-auto">


        <section class="text-gray-600 body-font bg-[#ffa800] shadow-lg relative z-10">
          <div class="container flex flex-col md:flex-row lg:max-w-5xl w-full px-5 py-12 md:py-24 mx-auto section"
            id="contact-form">
            <div class="md:w-1/3 w-full">
              <h1 class="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">Contact Us</h1>
              {/* <p class="leading-relaxed text-xl text-gray-900">
              We're here to assist you! If you have any questions or need assistance, please feel free to reach out to
              us.
              <br><br>
                You can also email us. Click here to reveal email address
                <a href="https://veilmail.io/e/FkKh7o" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">https://veilmail.io/e/FkKh7o</a>

              </p> */}




              <p class="leading-relaxed text-2xl text-gray-900 mt-8">
                📞 Phone: (877) 630-0487
                <br />

              </p>

              <p class="leading-relaxed text-xl text-gray-900 mt-8">
                <span className="text-2xl">
                  📧 Email: info@seguroamigo.app
                </span>
                <br />

              </p>


              <p class="leading-relaxed text-xl text-gray-900 mt-8">

                <span className="text-2xl">
                  🏣 Address: 2615 East End Blvd Suite 240 Marshall, TX 75672


                </span>
                <br />

              </p>

              <p class="leading-relaxed text-xl text-gray-900 mt-8 flex flex-col justify-center items-center">

                <span className="text-2xl my-2">Google Maps:</span>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.2717736581953!2d-94.35688082499198!3d32.518887697133955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8636f7311fc3847f%3A0x90550fe435f558d4!2sSeguro%20Amigo%20App!5e0!3m2!1sen!2sus!4v1720440789049!5m2!1sen!2sus"
                  width="410"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </p>

              {/* <span class="inline-flex mt-6 justify-center sm:justify-start">


              </span> */}
            </div>
            <div class="md:w-2/3 w-full mt-10 md:mt-0 md:pl-28">
              <h1 class="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">Contact Form</h1>
              <form onSubmit={handleSubmit} id="submit-contact-form">
                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="name" class="leading-7 py-4 text-lg text-gray-900">Your Name</label>
                    <input type="text" id="name" name="name"
                      class="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out "
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="mobile" class="leading-7 py-4 text-lg text-gray-900">Your Mobile Number</label>
                    <input
                      class="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out"
                      type="tel"
                      id="mobile"
                      name="mobile"
                      required
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="email" class="leading-7 py-4 text-lg text-gray-900">Your Email</label>
                    <input
                      class="w-full bg-white rounded border border-gray-400
                         focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out "
                      type="email"
                      id="email"
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleChange}

                    />
                  </div>
                </div>

                <div class="p-2 w-full">
                  <div class="relative">
                    <label for="message" class="leading-7 py-4 text-lg text-gray-900">Your Message</label>
                    <textarea
                      class="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-900 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out "

                      id="message"
                      name="message"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div class="p-2 w-full">
                  <button type="submit"
                    class="flex text-white bg-gray-900 border-0 py-4 px-6 focus:outline-none hover:bg-blue-900 rounded text-xl font-bold shadow-lg mx-0 flex-col text-center g-recaptcha"
                    id='sendmsg'
                    disabled={emaildiabled}
                  >
                    Send Message ✉
                  </button>
                </div>

                <div class="p-2 w-full flex items-start mt-4">
                  <input
                    type="checkbox"
                    id="smsOptIn"
                    name="smsOptIn"
                    class="mt-1 mr-2"
                    required
                  />
                  <label for="smsOptIn" class="text-sm text-gray-700">
                    By entering my mobile number and checking the SMS Opt-In box above, I agree to receive text messages from Seguro Amigo App. Message frequency varies by the level of customer support needed. Message + data rates may apply. Reply STOP to unsubscribe or HELP for help. View Privacy Policy <a href="/privacy-policy" class="font-bold underline">here</a>.
                  </label>
                </div>
              </form>
            </div>
          </div>
        </section>




        {/* <div
          className="flex md:w-full flex-col  justify-center text-[#202020] items-center   mainSection_"
          data-lang="aboutcontent"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1
            className="  my-10 md:text-7xl text-4xl max-md:text-center"
            data-lang="aboutheader"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Trans>home.paragraphs1.title</Trans>
          </h1>
          <p className="text-pretty text-md font-medium tracking-wide">
            <Trans>home.paragraphs1.p1</Trans>
            <span className="h-6 block w-full" />
          </p>

          <button
            className="text-black text-2xl p-3 px-4 mt-6 rounded-3xl border-4 border-black "
            onClick={toggleReadMore}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {showMore ? "Read Less" : "Read More"}
          </button>
        </div> */}
      </div>
    </div>
  );
};
