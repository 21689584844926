import React from 'react'
import '../style.css'
import '../../App.css'
import orange from '../../assets/images/graph.png'
import g1 from '../../assets/images/g1.png'
import { Trans, useTranslation } from 'react-i18next'
export const Blogs = () => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global")
  return (
    <div id="Blogs">
      <div className="window">
        <img src={orange} alt="hmm" className="bg" />
        <img src={g1} alt="hmm" className="bgs" />
        <h1><Trans>under.cons.say</Trans></h1>
        <p><Trans>under.cons.p</Trans></p>
      </div>
    </div>
  )
}
