// ChatWidget.js
import React, { useEffect } from 'react';

const ChatWidget = () => {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.src = 'https://widgets.leadconnectorhq.com/loader.js';
        script.setAttribute('data-resources-url', 'https://widgets.leadconnectorhq.com/chat-widget/loader.js');
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <chat-widget
    location-id="5lQ35ql5fq4re8CiHdA0"
    style={{
        '--chat-widget-primary-color': '#000000',
        '--chat-widget-active-color': '#000000',
        '--chat-widget-bubble-color': '#000000',
    }}
    heading="¿Tengo una pregunta?"
    sub-heading="Ingrese su pregunta a continuación y un representante se comunicará con usted."
    prompt-msg={`Hola, bienvenido a Seguro Amigo, déjanos saber cómo podemos ayudarte.
    ______________________
    Hi there, Welcome to Seguro Amigo, let us know how we can help you `}
    legal-msg="By submitting you agree to receive SMS or e-mails for the provided channel."
    use-email-field="true"
    support-contact="123456789"
    success-msg="Uno de nuestros representantes se pondrá en contacto con usted en breve."
    thank-you-msg="¡muchas gracias!"
    prompt-avatar="https://images.leadconnectorhq.com/image/f_webp/q_100/r_45/u_https://cdn.filesafe.space/locationPhotos%2F5lQ35ql5fq4re8CiHdA0%2Fchat-widget-person?alt=media&token=cc86bb77-953c-4f64-abb7-3a6204917493"
    agency-name="RevSpring"
    agency-website="link.strategysimple.com"
    locale="es"
    live-chat-intro-msg={`Danos un minuto para asignarte la mejor persona para ayudarte.
    
    First Name
    Last Name
    Phone Number
    Email Address`}
    chat-type="liveChat"
    live-chat-user-inactive-msg="Parece que está tardando un poco. Por favor, deje sus datos de contacto. Nos pondremos en contacto con usted en breve."
    live-chat-visitor-inactive-msg="Chat cerrado por inactividad del usuario."
    live-chat-ack-msg="Tu chat ha terminado."
    live-chat-feedback-note="Por favor califica tu experiencia."
    live-chat-end-msg="Para iniciar un nuevo chat,"
    primary-color="#000000"
    show-consent-checkbox="true"
></chat-widget>    
        </div>
    );
};

export default ChatWidget;
