import React, { useEffect, useRef, useState } from "react";
import jQuery from 'jquery';
import { Trans, useTranslation } from "react-i18next";

const Agent = ({ isPage = false }) => {
    const sectionRef = useRef(null);
    // eslint-disable-next-line
    const { t, i18n } = useTranslation("global");



    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize); // Clean up event listener
    }, []);



    const [showMore, setShowMore] = useState(isMobile);

    const toggleReadMore = async () => {
        setShowMore(!showMore);
    };

    // const morePara = <Trans>home.paragraphs1.p3</Trans>;

    // Apoyo Empresarial con Seguros Comerciales: No solo contamos con Seguros para autos, Seguro Amigo
    // App ofrece opciones completas para seguros comerciales. Sabemos de primera mano que muchos
    // inmigrantes que llegan a América también son exitosos propietarios de pequeñas empresas.
    // Entendemos los diferentes matices de iniciar y administrar un negocio, nuestra plataforma está
    // diseñada para brindar la protección que tu empresa merece. Continuaremos agregando más valor a esta
    // plataforma en cada oportunidad que tengamos que hacerlo. También escucharemos las necesidades de
    // nuestros clientes. Si no tenemos lo que TÚ necesitas, haremos todo lo posible por traerlo aquí y
    // brindártelo.
    // Más que Solo Seguros: Más allá de encontrar la póliza correcta, Seguro Amigo App sirve como un centro
    // de recursos. Proporcionamos enlaces e información valiosa para ayudarte en varios aspectos de tu vida,
    // desde asesoría legal hasta planificación financiera. Nuestra misión es hacer de esta plataforma una
    // comunidad que te ofrezca tanto apoyo como sea posible. Continuaremos agregando valor cada día.
    // Con Seguro Amigo App, eliges un AMIGO comprometido a proporcionarte las mejores soluciones de
    // seguros. Te invitamos a unirte a nuestra plataforma y experimentar un enfoque sin complicaciones para
    // encontrar el mejor seguro para tu auto.

    useEffect(() => {
        executeScroll();
        // window.addEventListener("scroll", reveal);
        // return () => {
        //   window.removeEventListener("scroll", reveal);
        // };
    }, []);
    const executeScroll = () => {
        isPage && sectionRef.current.scrollIntoView();
    };

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowheight = window.innerHeight;
            var revealtop = reveals[i].getBoundingClientRect().top;
            var revealpoint = 110;

            if (revealtop < windowheight - revealpoint) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }



    const [emaildiabled, setemaildiabled] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        city: '',
        state: '',
        // zipcode: '',
        Phone: '',
        ProducerLicenseNo: '',
        ProducerNPN: '',
        EOCarrier: '',
        EOPolicyNumber: '',
        EOExpirationDate: '',
        // StatesLicensedInPersonalLines: '',
        // StatesLicensedinGeneralLine: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() }); // Trim whitespace from input values
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { firstName, lastName, email, city, state,
            // zipcode,
            Phone,
            ProducerLicenseNo,
            ProducerNPN,
            EOCarrier,
            EOPolicyNumber,
            EOExpirationDate,
            // StatesLicensedInPersonalLines,
            // StatesLicensedinGeneralLine
        } = formData;

        // Check if all required fields are filled
        if (firstName && lastName && email && city && state &&
            // zipcode &&
            Phone && ProducerLicenseNo && ProducerNPN && EOCarrier && EOPolicyNumber && EOExpirationDate
            // && StatesLicensedInPersonalLines && StatesLicensedinGeneralLine
        ) {
            document.getElementById('submitbtnnn').style.backgroundColor = 'gray';

            jQuery.ajax({
                url: 'https://script.google.com/macros/s/AKfycbz6ROSgeGzSjU_OEuMt_tUocWClpngzfvqAnON1GzV2j-559I_STfactrgbqLGBH2q-AA/exec',
                // https://script.google.com/macros/s/AKfycbxA4XTpoGbaPbjC7-eEaliS2ei8bJ68gcPiLzTeq9Vnttaw8s5jKC6HQ8FeEyGUCjWgaw/exec',
                type: 'post',
                data: {
                    'firstname': firstName, 'lastname': lastName, 'emailid': email, 'city': city, 'state': state,  'phone': Phone, 'producerLicenseNo': ProducerLicenseNo, 'producerNPN': ProducerNPN, 'eOCarrier': EOCarrier, 'eOPolicyNumber': EOPolicyNumber, 'eOExpirationDate': EOExpirationDate
                },
                success: function (response) {
                    if (response === email) {
                        alert('After you submit your information, someone from our team will be in touch with soon to discuss an appointment');
                        console.log('Form submitted:', formData);
                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            city: '',
                            state: '',
                            Phone: '',
                            ProducerLicenseNo: '',
                            ProducerNPN: '',
                            EOCarrier: '',
                            EOPolicyNumber: '',
                            EOExpirationDate: '',
                           
                        });

                        window.location.reload();
                    }
                },
                error: function () {
                    alert('Error occurred while submitting the form. Please try again later.');
                }
            });
        } else {
            alert('Please enter all details');
        }
    };

    // Render button style and disabled state based on form validation
    const isFormValid = formData.firstName && formData.lastName && formData.email && formData.city && formData.state;
        // && formData.zipcode;
    const buttonStyle = `inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white ${isFormValid ? '' : 'bg-gray-100 text-white'}`;


    return (
        <div
            ref={sectionRef}
            className={`aboutme   bg-[url('assets/images/bg-about-us.png')] md:bg-cover bg-repeat flex
      ${isMobile ? "md:h-[1200px] md:bg-none bg-[#ffa800] mt-0" : "h-full"}
      `}
            id="about"
        >
            <div className="lg:w-1/4 justify-center mb-25 items-center mt-5 lg:m-0">
                <h1 class="text-6xl font-bold text-center mb-5">Become an Agent</h1>
                <div className=" items-center text-lg ">

                    <p class=" text-black font-bold p-2 pb-0   items-center">
                        {/* After you submit your information, someone from our team will be in touch with soon to discuss an appointment */}
                        A member of our team will reach out to you shortly to arrange a consultation and discuss your appointment in detail. We look forward to speaking with you.
                    </p>
                </div>

            </div>


            <div class="mx-auto rounded-lg mt-20 mb-20 lg:mt-0 lg:mb-0  px-4 py-10 sm:px-6 lg:px-8 bg-gray-100">

                <form onSubmit={handleSubmit} id="submit-contact-form" className="mx-auto text-left mb-0 mt-0 max-w-md space-y-4">



                    <div className="flex gap-3">

                        <div className="">

                            <p className="p-2 text-black">First Name</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-400 bg-gray-200 p-4 pe-12 text-sm shadow-sm"
                                    placeholder="Enter first name"
                                    name='firstName'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>


                        <div>

                            <p className="p-2 text-black">Last Name</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter last name"
                                    name='lastName'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>




                    <div className="flex gap-3">

                        <div>

                            <p className="p-2 text-black">Phone</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter Phone"
                                    name='Phone'
                                    value={formData.Phone}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>


                        <div>

                            <p className="p-2 text-black">Email</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter Email"
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                    </div>



                    <div>

                        <p className="p-2 text-black">Address</p>

                        <div class="relative">
                            <input
                                type="text"
                                class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                placeholder="Enter Address"
                                name='Address'
                                value={formData.Address}
                                onChange={handleChange}
                            />

                            <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>



                    <div className="flex gap-3">

                        <div>

                            <p className="p-2 text-black">City (Zipcode)</p>
                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter City"
                                    name='city'
                                    value={formData.city}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>



                        <div>

                            <p className="p-2 text-black">State</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter State"
                                    name='state'
                                    value={formData.state}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                    </div>




                    <div className="flex gap-3">

                        <div>

                            <p className="p-2 text-black">Producer License No.</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter Producer License Number"
                                    name='ProducerLicenseNo'
                                    value={formData.ProducerLicenseNo}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>



                        <div>

                            <p className="p-2 text-black">Producer NPN</p>

                            <div class="relative">
                                <input
                                    type="text"
                                    class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                    placeholder="Enter Producer NPN"
                                    name='ProducerNPN'
                                    value={formData.ProducerNPN}
                                    onChange={handleChange}
                                />

                                <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="size-4 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                    </div>








                    <div>

                        <p className="p-2 text-black">E&O Carrier</p>

                        <div class="relative">
                            <input
                                type="text"
                                class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                placeholder="Enter E&O Carrier"
                                name='EOCarrier'
                                value={formData.EOCarrier}
                                onChange={handleChange}
                            />

                            <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>


                    <div>

                        <p className="p-2 text-black">E&O Policy No.</p>

                        <div class="relative">
                            <input
                                type="text"
                                class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                placeholder="Enter E&O Policy No"
                                name='EOPolicyNumber'
                                value={formData.EOPolicyNumber}
                                onChange={handleChange}
                            />

                            <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>







                    <div className="">


                        <p className="p-2 text-black">E&O Expiration Date</p>

                        <div class="relative">
                            <input
                                type="text"
                                class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                placeholder="Enter E&O Expiration Date"
                                name='EOExpirationDate'
                                value={formData.EOExpirationDate}
                                onChange={handleChange}
                            />

                            <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>


                    {/* <div>

                        <p className="p-2 text-black">States Licensed In PersonalLines</p>

                        <div class="relative">
                            <input
                                type="text"
                                class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                placeholder="Enter City"
                                name='StatesLicensedInPersonalLines'
                                value={formData.StatesLicensedInPersonalLines}
                                onChange={handleChange}
                            />

                            <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>


                    <div>

                        <p className="p-2 text-black">States Licensedin GeneralLine</p>

                        <div class="relative">
                            <input
                                type="text"
                                class="w-full rounded-lg border-gray-200 p-4 pe-12 bg-gray-200  text-sm shadow-sm"
                                placeholder="Enter City"
                                name='StatesLicensedinGeneralLine'
                                value={formData.StatesLicensedinGeneralLine}
                                onChange={handleChange}
                            />

                            <span class="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div> */}


                    <div class="flex items-center justify-between">
                        {/* <p class="text-sm text-gray-500">
                            No account?
                            <a class="underline" href="#">Sign up</a>
                        </p> */}

                        <button
                            //     type="submit"
                            //     id="submitn"


                            class="inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white"
                            type="submit"
                            // className={buttonStyle}
                            id='submitbtnnn'
                        // disabled={isFormValid}

                        >
                            Submit Details
                        </button>
                    </div>
                </form>
            </div>






        </div>
    );
};


export default Agent;
