import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FileAclaim from "./containers/fileAClaim";
import { Home } from "./containers/home";
import { Footer } from "./containers/footer";
import { Terms } from "./components/Terms";
import { Blogs } from "./components/Blogs/Blogs";
import { Fast } from "./components/Fast";
// import { useIntercom } from "./customHook/Intercom";
import { BottomNavbar } from "./containers/BottomNavbar";
import TopNavbar from "./containers/TopNavbar";
import SubBar from "./containers/SubBar";
import { useState } from "react";
import { About } from "./containers/about";
import { Mission } from "./containers/mission";
import { ContactUs } from "./containers/ContactUs";
import Coverage from "./containers/coverage";
import "./containers/styles.css";
import PrivacyPolicyPage from "./containers/privacy-policy";
import TermsConditionPage from "./containers/terms-condition";
import Agent from "./containers/Agent"
import ChatWidget from './components/ChatWidget'; // Import the ChatWidget component

function App() {
  // useIntercom("xwskn3vv");
  const [zipmodal, setZipmodal] = useState(false);
  const [language, setLanguage] = useState("es");

  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <TopNavbar setLang={setLanguage} />
        <SubBar zipmodal={zipmodal} language={language} />
        {/* <BottomNavbar /> */}
        <div>
          <Routes>
            <Route path="/" element={<Home setZipmodal={setZipmodal} />} />
            <Route path="/walkthrough" element={<Home setZipmodal={setZipmodal} />} />
            <Route path="/walkthrough.html" element={<Home setZipmodal={setZipmodal} />} />
            <Route path="/file-a-claim" element={<FileAclaim />} />
            <Route path="/blogs-and-articles" element={<Blogs />} />
            <Route path="/fast-business-coverage-online" element={<Fast />} />
            <Route path="/about" element={<About isPage={true} />} />
            <Route path="/mission" element={<Mission isPage={true} />} />
            <Route path="/contactus" element={<ContactUs isPage={true} />} />
            <Route path="/coverage" element={<Coverage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/agent" element={<Agent />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsConditionPage />}
            />
          </Routes>
        </div>
        <ChatWidget />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
