import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import LogoPng from "../assets/images/logo.png";
import { Trans, useTranslation } from "react-i18next";
import LanguageDropDown from "../components/LanguageDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import ImageCarousel from "../components/ImageCarousel";
import MobileFast from "../assets/images/mobile_fast.png";
import MobileImmegrant from "../assets/images/mobile_immegrant.png";
import SpinnerLogo from "../assets/images/nav_logo_spinner.png";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import jQuery from 'jquery';

let ee = "/";
let ee1 = "/";
let advalue = "none";
let zocde;
const isMobileDevice = /Mobi/.test(navigator.userAgent);
const isInWebView = window.matchMedia('(display-mode: standalone)').matches;
let state1 = jQuery('#address-input');

const ZipCodeModal = ({
  zipCode,
  setZipCode,
  isSubmitDisabled,
  handleZipCodeChange,
  showBanner,
  error,
  errormsg,
}) => {
  const handleSubmit = (e) => {

    showBanner();
    e.preventDefault();
    const zipcodeValue = jQuery('#zipcode-input').val();
    zocde = zipcodeValue;
    console.log(zipcodeValue);

    // const zipcodeValue1 = '10011';


    // if (zipcodeValue <= 999999 && zipcodeValue >= 0) {
    //   closeModal();
    //   localStorage.setItem("link", "http://kgwlawfirm.com/personal-injury-attorney-dallas/");
    //   localStorage.setItem("link2", "http://kgwlawfirm.com/immigration-attorney-dallas/");
    // }


    const zipcodeStr = String(zipcodeValue);

    // Check if the zipcode is six digits and within the range 000000-999999
    // if (zipcodeStr.length === 6 && zipcodeValue >= 0 && zipcodeValue <= 999999) {
    closeModal();
    localStorage.setItem("link", "http://kgwlawfirm.com/personal-injury-attorney-dallas/");
    localStorage.setItem("link2", "http://kgwlawfirm.com/immigration-attorney-dallas/");
    // }

    // else {
    //   localStorage.setItem("link", "/");
    //   localStorage.setItem("link2", "/");

    // }

    // http://kgwlawfirm.com/immigration-attorney-dallas/

    // jQuery.ajax({
    //   url: 'https://script.google.com/macros/s/AKfycbyP7TzyvoR21DPDxxlcTM6_ZHD6u4qA8hHN2E-DW5ut0JlCfVfO_bz44BWBbSMtvDPRKg/exec',
    //   // url: 'https://script.google.com/macros/s/AKfycbyUCmLsMK0rSABAQdmUgToZblWfAOHozL9QLv3SKZkx6viEzJwlDzwbL6LLn4WEdlv9/exec',
    //   type: 'post',
    //   data: { 'zipcode': zipcodeValue }, // Use an object with a key 'zipcode'
    //   success: function (r) {
    //     // Handle success if needed
    //     console.log("r = " + r);
    //     ee = r;
    //     if (r == 0) {
    //       console.log("not found");
    //       localStorage.setItem("link", "/");
    //       // showBanner(0);
    //       closeModal();
    //       // alert("Code Not found");

    //       // window.location.reload();
    //       // showBanner();

    //     }
    //     else {
    //       localStorage.setItem("link", ee);
    //       // localStorage.setItem("Adress", addressValue);
    //       // advalue = addressValue;
    //       // showBanner();
    //       closeModal();

    //     }

    //   },
    //   error: function (xhr, status, error) {
    //     // ee = "-1"; // Set ee to undefined in case of error
    //     console.error('Error:', error);
    //     console.log("error = " + ee + status);
    //   },
    // });


    // jQuery.ajax({
    //   url: 'https://script.google.com/macros/s/AKfycbwwBHQkVpbMged83V4UZ6bMBlQhCjn_EoiOugqcMQz9z2TvBwQmpam5XNh0meRo-wMq/exec',
    //   // url: 'https://script.google.com/macros/s/AKfycbyUCmLsMK0rSABAQdmUgToZblWfAOHozL9QLv3SKZkx6viEzJwlDzwbL6LLn4WEdlv9/exec',
    //   type: 'post',
    //   data: { 'zipcode': zipcodeValue }, // Use an object with a key 'zipcode'
    //   success: function (r1) {
    //     // Handle success if needed
    //     console.log("r1 = " + r1);
    //     ee1 = r1;
    //     if (r1 == 0) {
    //       console.log("not found");
    //       localStorage.setItem("link2", "/");
    //       // showBanner(0);
    //       // closeModal();
    //       // alert("Code Not found");

    //       // window.location.reload();
    //       // showBanner();

    //     }
    //     else {
    //       localStorage.setItem("link2", ee1);
    //       // localStorage.setItem("Adress", addressValue);
    //       // advalue = addressValue;
    //       // showBanner();
    //       // closeModal();

    //     }

    //   },
    //   error: function (xhr, status, error) {
    //     // ee = "-1"; // Set ee to undefined in case of error
    //     console.error('Error:', error);
    //     console.log("error = " + ee + status);
    //   },
    // });

  };



  const LOADING_BG_COLOR = "#000";
  const LOADING_TEXT_COLOR = "#fff";
  const ERROR_BG_COLOR = "#f7ae1b";
  const INVALID_ZIP_MESSAGE = "Invalid zip code.";
  const ASYNC_OPERATION_DELAY = 2000; // milliseconds




  function location2() {

    let city, state, address, pincode, lat1, long1, district;

    // Request geolocation permission every time location2() is called
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;

          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${long}`;
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              state = data.address.state;
              city = data.address.city;
              district = data.address.district;
              address = data.display_name;
              pincode = data.address.postcode;
              lat1 = position.coords.latitude; // Assign lat and long here
              long1 = position.coords.longitude;

              console.log(`Your current city is ${city}`);
              console.log(`Your current state is ${state}`);
              console.log(`Your current address is ${address}`);
              console.log(`Your current zipcode is ${pincode}`);
              console.log(`Your current lat is ${lat1}`);
              console.log(`Your current long is ${long1}`);

              // Check if city, state, address, etc. are undefined or null and handle them as needed


              // Perform AJAX call with the retrieved data
              jQuery.ajax({
                url: 'https://script.google.com/macros/s/AKfycbw70vMK7K_y6C8sr1lABNDdPrwXtuwpxDDJva5ZI1DUWUnQl0JhWdpPPqBmZkwW-0g/exec',
                type: 'post',
                data: { 'zipcode': zocde, 'city': city, 'state': state, 'address': address, 'pincode': pincode, 'lat': lat1, 'long': long1 },
              });
            })
            .catch(error => {
              console.error(error);

              // if (address1 != ' ') { location3(address1); }
            });
        },
        function (error) {
          console.log("User denied the request for geolocation.");
          // let address1 = state1.value;
          // if (address1 != ' ') { location3(address1); }
        }
      );
    } else {
      // let address1 = state1.value;
      // if (address1 != ' ') { location3(address1); }
    }
  }




  // function location3() {


  //   let city, state, address, pincode, district;

  //   // Request geolocation permission every time location2() is called
  //   // navigator.geolocation.getCurrentPosition(
  //   //     function (position) {
  //   // const lat = position.coords.latitude;
  //   // const long = position.coords.longitude;

  //   // const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${long}`;
  //   // fetch(apiUrl)
  //   //     .then(response => response.json())
  //   //     .then(data => {
  //   // state = data.address.state;
  //   // city = data.address.city;
  //   // district = data.address.district;
  //   // address = data.display_name;
  //   // pincode = data.address.postcode;
  //   // lat1 = position.coords.latitude; // Assign lat and long here
  //   // long1 = position.coords.longitude;

  //   console.log('Your pppppp city is' + advalue);


  //   // Check if city, state, address, etc. are undefined or null and handle them as needed

  //   // Perform AJAX call with the retrieved data
  //   jQuery.ajax({
  //     url: 'https://script.google.com/macros/s/AKfycbxAgnbz5e9k4mUEw465NTtjt6d10XeJ5TVVQbPPi0b6WADauwZZbK8cmPr5zMpO9R1J/exec',
  //     type: 'post',
  //     data: { 'zipcode': zocde, 'city': 'none', 'state': 'none', 'address': advalue, 'pincode': 'none', 'lat': 'none', 'long': 'none' },
  //   });
  //   // })
  //   // .catch(error => {
  //   //     console.error(error);
  //   // });
  //   // },
  //   // function (error) {
  //   //     console.log("User denied the request for geolocation.");
  //   // }
  //   // );
  // }



  function showBanner() {
    var zip = jQuery('#zipcode-input').val();
    var errorText = document.getElementById("errorText");
    var bg = document.querySelector("#zipModalContent");
    var hero = document.querySelector(".doth1");
    var loadingSpinner = document.getElementById("loadingSpinner");

    bg.style.backgroundColor = LOADING_BG_COLOR;
    hero.style.color = LOADING_TEXT_COLOR;
    loadingSpinner.style.display = "inline-block";
    // Save zip code to local storage
    localStorage.setItem("zipCode", zip);


    if (hero) {
      // bg.style.backgroundColor = LOADING_BG_COLOR;
      // hero.style.color = LOADING_TEXT_COLOR;
      // loadingSpinner.style.display = "inline-block";

      disableClicksOutsideModal();



      setTimeout(function () {
        if (zip.toString().length >= 5) {
          // if (zip.toString().length >= 5) {
          // errorText.textContent = "";
          // closeModal();
          setTimeout(() => {
            // location2();

            // if (!isMobileDevice) {
            //   console.log('This is a mobile device.');
            // } else {
            //   console.log('This is not a mobile device (e.g., PC, laptop).');

            //   let address1 = state1.value;

            //   if (address1 != ' ') { location3(address1); }

            //   else {
            //     console.log('address is empty');
            //   }
            // }

            // location2();



          }, 500);

        } else {
          bg.style.backgroundColor = ERROR_BG_COLOR;
          errorText.textContent = INVALID_ZIP_MESSAGE;
        }

        // Hide loading spinner and enable clicks outside the modal
        // loadingSpinner.style.display = "none";
        enableClicksOutsideModal();
      }, ASYNC_OPERATION_DELAY);
    } else {
      console.error("Element with class '.doth1' not found!");
    }

    // }




  }





  function preventClickOutside(event) {
    var modalContent = document.getElementById("zipModalContent");
    if (!modalContent.contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function enableClicksOutsideModal() {
    document.body.removeEventListener("click", preventClickOutside);
  }

  function closeModal() {
    var loadingSpinner = document.getElementById("loadingSpinner");
    loadingSpinner.display = "none";
    document.getElementById("zipModal").style.display = "none";
    // Enable clicks outside the modal when the modal is closed
    enableClicksOutsideModal();
    location2();

  }

  function disableClicksOutsideModal() {
    document.body.addEventListener("click", preventClickOutside);
  }





  return (
    <>





      <div id="zipModal" className="zipModalPC pt-20">
        <div id="zipModalContent">
          <h1 className="doth1">
            Para proporcionar las cotizaciones más económicas, complete su código postal
          </h1>
          <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            required
            id="zipcode-input"
            placeholder="Zipcode"
            value={zipCode}
            onChange={handleZipCodeChange}
          />

          {/* <br /> */}
          {error && <p className="error text-red-500 font-bold">{errormsg}</p>}
          <button
            id="submitBtn"
            className="mt-5"
            disabled={isSubmitDisabled || error}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <div id="loadingSpinner"></div>
        </div>
      </div>

    </>
  );
};



function TopNavbar({ setLang }) {





  const [link, setLink] = useState('');

  // Fetch initial link from localStorage (optional)
  useEffect(() => {

    // const isMobileDevice = /Mobi/.test(navigator.userAgent);
    // const isInWebView = window.matchMedia('(display-mode: standalone)').matches;
    // if (!isMobileDevice) {
    //   // Hide the address-input element using appropriate method:
    //   const addressInput = document.getElementById('address-input');
    //   addressInput.style.display = 'none'; // Or use 'hidden' if required
    //   // advalue = "hidden"; // Update advalue if necessary
    // }

    const storedLink = localStorage.getItem('link');
    if (storedLink) {
      setLink(storedLink);
    }


  }, []);

  const handleClick = () => {
    // const storedLink = localStorage.getItem('link');
    // const storedLink = localStorage.getItem('link');
    const storedLink = "http://kgwlawfirm.com/personal-injury-attorney-dallas/";

    if (storedLink) {
      handleNavigateWithDelay(storedLink);
    } else {
      // Fallback logic if no link is found (e.g., display message or navigate elsewhere)
      handleNavigateWithDelay("/");

    }
  };


  const handleClickIMG = () => {
    // alert("handleClickIMG");
    // const storedLink = localStorage.getItem('link2');
    const storedLink = "http://kgwlawfirm.com/immigration-attorney-dallas/";
    if (storedLink) {
      handleNavigateWithDelay(storedLink);
    } else {
      // Fallback logic if no link is found (e.g., display message or navigate elsewhere)
      handleNavigateWithDelay("/");

    }
  };


  // const handleSubscribeClick = () => {

  // };

  const [selectedPage, setSelectedPage] = useState("Home");
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setLanguage] = useState("es");
  const INVALID_ZIP_MESSAGE = "Invalid zip code.";

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    setLanguage(value);
    setLang(value);
    console.log("lang change");
  };
  console.log("selectedLanguage", selectedLanguage);
  const [showMenu, setShowMenu] = useState(0);
  const [toggle, setToggle] = useState(false);
  const history = useNavigate();
  const handleNavigation = (path) => {
    setToggle(false);
    history(path);
  };

  useEffect(() => {
    // Check if zip code exists in local storage
    const storedZipCode = localStorage.getItem("zipCode");

    // If zip code exists, set it to state
    if (storedZipCode) {
      setZipCode(storedZipCode);
      setIsZipCode(true);
    } else {
      setIsZipCode(false);
    }
  }, []);


  React.useEffect(() => {
    //add event listener to window resize
    //and change the state of the menu to close if the window is resized
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    });
  }, []);

  const navigation = [
    { name: <Trans>navbar.home</Trans>, href: "/", current: true },
    {
      name: <Trans>navbar.fileAClaim</Trans>,
      href: "/file-a-claim",
      current: false,
    },
    // {
    //   name: <Trans>navbar.terms</Trans>,
    //   href: "/terms-and-conditions",
    //   current: false,
    // },
    {
      name: <Trans>navbar.about</Trans>,
      href: "/about",
      current: false,
    },
    {
      name: <Trans>navbar.blog</Trans>,
      href: "/blogs-and-articles",
      current: false,
    },
    { name: <Trans>navbar.mission</Trans>, href: "/mission", current: true },
    { name: <Trans>navbar.contactus</Trans>, href: "/contactus", current: true },
    { name: <Trans>Become Agent</Trans>, href: "/agent", current: true },

  ];
  const registration = [
    {
      name: <Trans>navbar.login</Trans>,
      href: "https://seguroamigo.insuredmine.com/user/session/loginone",
      current: false,
    },
    {
      name: <Trans>navbar.signup</Trans>,
      href: "https://seguroamigo.insuredmine.com/user/session/loginone",
      current: false,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  // Function to handle zip code change
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  // function handleNavigateWithDelay(url) {

  //   console.log("handleNavigateWithDelay = " + url);
  //   setTimeout(() => {
  //     // window.location.href = url;
  //     window.location.href = url.includes('http') ? url : 'http://' + url;
  //   }, 100); // Adjust the delay as needed
  // }


  // function handleNavigateWithDelay(url) {
  //   if (url !== "/") {
  //     setTimeout(() => {
  //       // window.location.href = url;
  //       window.location.href = url.includes('http') ? url : 'http://' + url;
  //     }, 100);
  //   }
  // }

  // function handleNavigateWithDelay(url) {
  //   if (url !== "/") {
  //     setTimeout(() => {
  //       window.location.href = url.includes('http') ? url : 'http://' + url;
  //     }, 100);
  //   }
  // }


  function handleNavigateWithDelay(url) {
    if (url !== "/") {
      setTimeout(() => {
        // Check if the URL starts with http or https
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url;
        }
        window.location.href = url; // Navigate to the URL
      }, 100);
    }
  }

  // Open the URL in a new tab
  // window.open(url.includes('http') ? url : 'http://' + url, '_blank');
  // window.open(url.includes('http') ? url : 'http://' + url);

  const handleZipCodeChange = (event) => {
    const newZipCode = event.target.value;
    setZipCode(newZipCode);
    setSubmitDisabled(newZipCode.trim() === "");
    //validate zip code if it's 5 digits
    // if (
    //   (newZipCode.trim().length < 5 && newZipCode.trim().length > 1) ||
    //   newZipCode.trim().length > 5
    // ) {
    //   setError(true);
    //   setErrormsg(INVALID_ZIP_MESSAGE);
    // }
    // if (newZipCode.trim().length === 5) {
    //   setError(false);
    //   setErrormsg("");
    // }
  };
  const [zipCode, setZipCode] = useState("");
  const [isZipCode, setIsZipCode] = useState(true);
  const [error, setError] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  useEffect(() => {
    const zipCode = localStorage.getItem("zipCode");
    if (zipCode) {
      setIsZipCode(true);
    } else {
      setIsZipCode(false);
    }
  }, []);

  useEffect(() => {
    const zipCode = localStorage.getItem("zipCode");
    if (zipCode) {
      setZipCode(zipCode);
    }
  }, []);
  function showBanner() {
    localStorage.setItem("zipCode", zipCode);
    setIsZipCode(true);
  }



  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up event listener
  }, []);



  return (
    <>









      {!isZipCode && (
        <ZipCodeModal
          zipCode={zipCode}
          setZipCode={setZipCode}
          isSubmitDisabled={isSubmitDisabled}
          handleZipCodeChange={handleZipCodeChange}
          showBanner={showBanner}
          error={error}
          errormsg={errormsg}
        />
      )}
      <ImageCarousel />




      <div as="nav" className="w-full py-5">
        <div className="mx-auto max-w-9xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 justify-between">
            <div className="absolute inset-y-0 left-0 flex sm:hidden w-full justify-end">
              {/* Mobile menu button*/}
              <div className="flex mt-2 items-center sm:hidden">
                <span className="absolute " />
                <span className="sr-only">Open main menu</span>
                {toggle ? (
                  <FontAwesomeIcon
                    fill="white"
                    scale={5}
                    style={{ zIndex: 1000 }}
                    className="h-8 w-8 text-white outline-white md:hidden block cursor-pointer"
                    icon={faXmark}
                    onClick={() => setToggle(!toggle)}
                  />
                ) : (
                  <FontAwesomeIcon
                    fill="white"
                    style={{ zIndex: 1000 }}
                    className="h-8 w-8 text-white outline-white md:hidden block cursor-pointer"
                    icon={faBars}
                    onClick={() => setToggle(!toggle)}
                  />
                )}
              </div>


            </div>



            <div className="flex  items-center justify-center sm:items-stretch sm:justify-start">
              <div
                className="flex flex-shrink-0 items-center "
                style={{ zIndex: 1000 }}
              >



                <Link to={"/"}>
                  {/* NAV LOGO */}

                  {!isMobile ?
                    <img
                      src={LogoPng}
                      alt=""
                      className="w-[100px] h-[100px] md:mr-[80px] lg:block md:hidden block hover:cursor-pointer"
                    />
                    :
                    <div
                      className="flex text-xl  px-4 justify-center items-center"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <a
                        href="https://wh-app.io/green-lite-insurance-agency/get-quotes"
                        className="text-1"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          // fontSize: "25px",
                          justifyContent: "center",
                          alignItems: "center",
                          textDecoration: "underline",
                          textDecorationThickness: "3px",
                        }}
                        data-lang="get-quote"
                        onClick={(e) => {
                          window.location.href = "https://wh-app.io/green-lite-insurance-agency/get-quotes";
                        }}
                      >
                        {/* <Trans>navbar.business_ins</Trans> */}
                        Business Insurance
                      </a>
                    </div>
                  }

                </Link>


              </div>
            </div>



            {/* 
            // LIVE STATE BUTTON DISPLAY ONLY IN MOBILE NOT IN PC
            {isMobile ?

              <div
                className="items-center flex"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <a
                  href="https://live-states.vercel.app/"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  className="glow-on-hover1 text-black"
                  data-lang="get-quote"
                  onClick={(e) => {
                    window.location.href = "https://live-states.vercel.app/";
                  }}
                >
                  <Trans>navbar.livestate</Trans>
                </a>

              </div> : ''
            } */}










            <div className="items-center flex" style={{ zIndex: 1000 }}>
              <div className="flex space-x-4">
                <Link
                  to="/"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  About
                </Link>
                <Link
                  to="/file-a-claim"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  File a Claim
                </Link>
                <Link
                  to="/blogs-and-articles"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  Blog
                </Link>
                <Link
                  to="/mission"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  Mission
                </Link>

                <Link
                  to="/contactus"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  Contact us
                </Link>


                <Link
                  to="/agent"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  <Trans>Become Agent</Trans>
                </Link>

                {/* <Link
                  to="https://live-states.vercel.app/"
                  className="hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] text-white font-medium"
                >
                  <Trans>Live States</Trans>
                </Link> */}

                {/* {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      `hidden md:block rounded-md px-3 py-2 text-md hover:text-[#f9a602] ${item.name.props?.children === "navbar.signup"
                        ? "text-black"
                        : "text-white font-medium "
                      }${item.name.props?.children === "navbar.login" &&
                      "border-2 border-[#f9a602] rounded-3xl text-[#f9a602] p-4 hover:bg-[#f9a602] hover:text-black font-extrabold"
                      } ${item.name.props?.children === "navbar.signup" &&
                      "border-2 border-[#f9a602] rounded-3xl text-black p-4 bg-[#f9a602] hover:bg-transparent hover:text-[#f9a602] font-extrabold"
                      }`
                    )}
                    aria-current={item.current ? "page" : undefined}
                    onClick={() => setSelectedPage(item.name)}
                  >
                    {item.name}
                  </Link>
                ))} */}
              </div>
            </div>

            {/* 
            <div className="flex space-x-4 mt-2">
              <div className="flex md:mr-0 mr-10">
                <p
                  className="hidden md:flex rounded-md px-5 md:h-[50px] py-2 text-md items-center text-center
                  border-2 border-[#f9a602] text-[#f9a602] hover:bg-[#f9a602] hover:text-black"
                  onClick={handleSubscribeClick}
                >
                  Subscribe
                </p>
              </div>
            </div>
 */}

            {/* subcribe  */}
            {/* <div className="items-center flex" style={{ zIndex: 1000 }}>

              <a
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                className="glow-on-hover1 text-black"
                data-lang="get-quote"
                onClick={(e) => setShowSubscribeBox(true)}
              >
                <Trans>Subscibe</Trans>
              </a>
            </div> */}


            <div className="flex space-x-4 mt-2">
              <div className="flex md:mr-0 mr-10">





                <LanguageDropDown
                  selectedLanguage={selectedLanguage}
                  setLanguage={handleLanguageChange}
                />
              </div>
              {registration.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  style={{ zIndex: 1000 }}
                  className={classNames(
                    `hidden md:flex rounded-md px-5  md:h-[50px] py-2 text-md items-center text-center  ${item.name.props?.children === "navbar.signup"
                      ? "text-black"
                      : "text-white font-medium "
                    }${item.name.props?.children === "navbar.login" &&
                    "border-2 border-[#f9a602] rounded-3xl text-[#f9a602]  hover:bg-[#f9a602] hover:text-black "
                    } ${item.name.props?.children === "navbar.signup" &&
                    "border-2 border-[#f9a602] rounded-3xl text-black  bg-[#f9a602] hover:bg-transparent hover:text-[#f9a602] "
                    }
                    `
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => setSelectedPage(item.name)}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>





        {/* Mobile Menu  */}

        <div
          className={`md:hidden block ${toggle ? "left-0" : "-left-full -translate-x-full"
            } overflow-hidden transition-all duration-1000 ease-in-out
              flex flex-col justify-center items-left absolute bg-[#222327]  w-full mt-5
              `}
          style={{ zIndex: 1000 }}
        >




          <div className="flex flex-col w-full gap-6 pt-5 text-[#f9a602] pl-5 border-y-2 border-[#f9a602]  py-5">
            <div className="flex flex-col justify-center items-left ">

              {/* <div className="flex flex-row justify-start items-center gap-3 ">
                <p
                  className="w-[40px] h-[40px]"
                ></p>
                <p className="text-lg font-semibold">
                  <Trans>Become An Agent</Trans>
                </p>
              </div> */}

              <div className="flex flex-row justify-start items-center gap-3 ">
                {/* <img
                  src={SpinnerLogo}
                  alt="SpinnerLogo"
                  style={{
                    animation: "spin 5s linear infinite",
                  }}
                  className="w-[40px] h-[40px]"
                /> */}
                <p className="text-lg font-semibold">
                  <Trans>navbar.immigrants</Trans>
                </p>
              </div>


              {/* <a
                href="https://quote.seguroamigo.app/"
                // style="text-align: center; display: flex; justify-content: center; align-items: center;"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                className="glow-on-hover"
                data-lang="get-quote"
                onClick={(e) => {
                  window.location.href = "https://quote.seguroamigo.app";
                }}
              >
                <Trans>PRAYAG</Trans>
              </a> */}

              <div className="flex flex-col gap-y-3 text-base  text-white ml-14 mt-4 ">
                <Link
                  to="/"
                  data-lang="point1"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(showMenu === 1 ? 0 : 1);
                  }}
                >
                  {" "}
                  <Trans>navbar.dropdown.firstdrop.title</Trans>
                  <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                </Link>
                <div
                  className={`flex flex-col gap-y-2 text-[16px] font-[400] text-[#f9a602] 
                    transition-all duration-700 ease-in-out overflow-hidden
                    ml-6 ${showMenu === 1 ? "h-auto mb-4" : "h-0"}`}
                >

                  <a
                    className="hover:bg-[#2c3e50] p-2 hover:text-white  w-full rounded-md"
                    // href="#home"
                    data-lang="subpoint11"

                    onClick={(e) => {
                      handleClickIMG();

                      e.preventDefault(); // Prevent default behavior
                    }}
                  >
                    <Trans>navbar.dropdown.firstdrop.firstt</Trans>
                  </a>

                  <a
                    className="hover:bg-[#2c3e50] p-2 hover:text-white  w-full rounded-md"
                    href="#home"
                    data-lang="subpoint11"
                  >
                    <Trans>navbar.dropdown.firstdrop.first</Trans>
                  </a>
                  <a
                    // href="www.immigrationforum.org"
                    // target="_blank"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default browser behavior (link opening in new tab)
                      window.location.href = "https://www.immigrationforum.org/"; // Open in current tab
                    }}
                    rel="noreferrer"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.firstdrop.second</Trans>
                  </a>
                  <a
                    href="https://www.americanprogress.org/article/providing-identification-to-unauthorized-immigrants/"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint13"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.firstdrop.third</Trans>
                  </a>
                  <a
                    href="https://www.ilrc.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.firstdrop.fourth</Trans>
                  </a>
                  <a
                    href="https://frac.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.firstdrop.fifth</Trans>
                  </a>
                  <a
                    href="https://www.nilc.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.firstdrop.sixth</Trans>
                  </a>
                  <a
                    href="https://www.informedimmigrant.com/resources/mental-health/mental-health-undocumented-immigrants/"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint16"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.firstdrop.seventh</Trans>
                  </a>
                </div>
                <Link
                  to="/"
                  data-lang="point1"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(showMenu === 2 ? 0 : 2);
                  }}
                >
                  {" "}
                  <Trans>navbar.dropdown.seconddrop.title</Trans>
                  <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                </Link>
                <div
                  className={`flex flex-col gap-y-3 text-[16px] font-[400] text-[#f9a602] 
                    transition-all duration-700 ease-in-out overflow-hidden
                    ml-6 ${showMenu === 2 ? "h-auto mb-4" : "h-0"}`}
                >
                  <a
                    href="https://unitedwedream.org"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint21"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.seconddrop.first</Trans>
                  </a>

                  <a
                    href="https://www.ushcc.com/"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint22"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.seconddrop.second</Trans>{" "}
                  </a>

                  <a
                    href="https://chci.org"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint23"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.seconddrop.third</Trans>
                  </a>

                  <a
                    href="https://www.laycdc.org"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint24"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.seconddrop.fourth</Trans>{" "}
                  </a>

                  <a
                    href="http://www.lcdanm.org"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint25"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.seconddrop.fifth</Trans>
                  </a>

                  <a
                    href="https://www.maof.org"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint26"
                    className="hover:bg-[#2c3e50] hover:text-white p-2 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.seconddrop.sixth</Trans>
                  </a>
                </div>
                <Link
                  to="/"
                  data-lang="point1"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(showMenu === 3 ? 0 : 3);
                  }}
                >
                  {" "}
                  <Trans>navbar.dropdown.thirddrop.title</Trans>
                  <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                </Link>
                <div
                  className={`flex flex-col gap-y-3 text-[16px] font-[400] text-[#f9a602] 
                    transition-all duration-700 ease-in-out overflow-hidden
                    ml-6 ${showMenu === 3 ? "h-auto mb-4" : "h-0"}`}
                >
                  <a
                    href="https://www.uscis.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint31"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.first</Trans>
                  </a>

                  <a
                    href="https://www.acf.hhs.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint32"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.second</Trans>
                  </a>

                  <a
                    href="https://www.justice.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint33"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.third</Trans>{" "}
                  </a>

                  <a
                    href="https://www.hhs.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint34"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.fourth</Trans>
                  </a>

                  <a
                    href="https://www.eeoc.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint35"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.fifth</Trans>
                  </a>

                  <a
                    href="https://www.ed.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint36"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.sixth</Trans>
                  </a>

                  <a
                    href="https://www.hud.gov"
                    target="_blank"
                    rel="noreferrer"
                    data-lang="subpoint37"
                    className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                  >
                    <Trans>navbar.dropdown.thirddrop.seventh</Trans>
                  </a>
                </div>


                <p
                  onClick={handleClick}
                  // onClick={(e) => {
                  //   handleNavigateWithDelay(
                  //     parseInt(zipCode) >= 73002 && parseInt(zipCode) <= 74966
                  //       ? "https://parrishdevaughn.com/"
                  //       : parseInt(zipCode) >= 75001 &&
                  //         parseInt(zipCode) <= 79942
                  //         ? "https://wsfirm.com/practice-areas/personal-injury/car-accidents/"
                  //         : "/"
                  //   );


                  //   e.preventDefault(); // Prevent default behavior
                  // }}
                  data-lang="point4"
                  className="hover:bg-[#2c3e50] hover:text-white p-1 w-full rounded-md"
                >
                  <Trans>navbar.dropdown.fourthdrop</Trans>
                </p>
                {/* <a
                    href="https://parrishdevaughn.com/"
                    data-lang="subpoint37"
                  > <Trans>navbar.dropdown.fourthdrop</Trans>
                  </a> */}
              </div>
            </div>

            {/* <Link to={"/coverage"} className="flex items-center gap-3" onClick={() => setToggle(!toggle)}>
              <img
                src={MobileFast}
                alt="mobile_fast"
                style={{
                  animation: "spin 5s linear infinite",
                }}
                className="w-[40px] h-[40px]"

              />
              <p className="text-[18px] font-[600] ">
                
                <Trans>navbar.fast</Trans>
              </p>
            </Link> */}

            <a
              target="_blank"
              rel="noreferrer"
              id="websideimmgigration1"

              onClick={(e) => {
                handleClickIMG();

                e.preventDefault(); // Prevent default behavior
              }}
              className="flex items-center gap-3 hover:bg-gray-500"
            >
              {/* <img
                src={MobileImmegrant}
                alt="MobileImmegrant"
                style={{
                  animation: "spin 5s linear infinite",
                }}

                onClick={(e) => {
                  handleClickIMG();

                  e.preventDefault(); // Prevent default behavior
                }}

                className="w-[40px] h-[40px] hover:bg-gray-500"
              /> */}
              <p
                className="text-[18px] font-[500] hover:bg-gray-500"
                onClick={(e) => {
                  handleClickIMG();
                  // alert("hi am here SUBNAVBAR");
                  e.preventDefault(); // Prevent default behavior
                }}
              >
                <Trans

                  onClick={(e) => {
                    handleClickIMG();
                    // alert("hi am here SUBNAVBAR");
                    e.preventDefault(); // Prevent default behavior
                  }}
                  className="hover:bg-gray-500"
                >navbar.legalservices</Trans>
              </p>
            </a>
          </div>
          <div className="space-y-1 px-2 pb-3 pt-2 w-full">
            {navigation.map((item) => (
              <p
                key={item.name}
                as="a"
                // to={item.href}
                className={classNames(
                  selectedPage == item.name
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
                onClick={() => handleNavigation(item.href)}
              >
                <span className="font-sans">{item.name}</span>
              </p>
            ))}

            {registration.map((item) => (
              <a
                key={item.name}
                as="a"
                href={item.href}
                // to={item.href}
                className={classNames(
                  selectedPage == item.name
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
                onClick={() => setToggle(false)}
              >
                <span className="font-sans">{item.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavbar;
