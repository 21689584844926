import React from "react";
import { Trans } from "react-i18next";

const TermsConditionPage = ({ isPage = false }) => {
  return (
    <div
      className={`aboutme  bg-[url('assets/images/bg-about-us.png')] md:bg-cover bg-repeat flex md:justify-center items-center      `}
    >
      <div className="text-[22px] font-bold mt-5 container mx-auto">
        <div
          className="flex md:w-full flex-col  justify-center text-[#202020] items-center   mainSection_"
          data-lang="aboutcontent"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1
            className="  my-10 text-7xl max-md:text-center"
            data-lang="aboutheader"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Terms and Conditions
          </h1>
          <p className="text-pretty text-md font-medium tracking-wide">
            Please read these Terms and Conditions (“Terms”, “Terms and
            Conditions”) carefully before using the www.seguroamigo.app website
            (“Website”, “Site”) operated by DBB Capital Ventures LLC (“the
            Company”, “us”, “we”, or “our”). Your access to use the Site is
            conditioned on your acceptance of and compliance with these Terms.
            These terms apply to all www.seguroamigo.app visitors, users, and
            others who wish to use the Site. By accessing or using the Site you
            agree to be bound by these Terms. If you disagree with any part of
            the Terms you are not granted permission to access or otherwise use
            the Site. These terms apply only to your use of www.seguroamigo.app
            and do not change or modify any other agreement of contract between
            you and DBB Capital Ventures LLC. DBB Capital Ventures LLC reserves
            the right, in its sole discretion, to change these Terms and
            Conditions at any time without prior notice to you. Your continued
            use of the Site after any change may be made to the Terms
            constitutes acceptance of the change or changes. To the extent
            permitted by law, DBB Capital Ventures LLC disclaims all warranties,
            express or implied, including, but not limited to, implied
            warranties of merchantability and ﬁtness for a particular purpose.
            DBB Capital Ventures LLC does not warrant that the functionality or
            operation of the Site will be uninterrupted or free from error, that
            any defects in the Site will be corrected, or that the Site or the
            server(s) that make it available are free of viruses or other
            harmful conditions or components. Intellectual Property Rights The
            Website and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by DBB Capital Ventures LLC, its
            licensors, or other providers of such material and are protected by
            United States and international copyright, trademark, patent, trade
            secret, and other intellectual property or proprietary rights laws.
            These Terms and Conditions permit you to use the Website for your
            personal, non-commercial use only. Except as expressly provided
            herein, you must not reproduce, distribute, modify, create
            derivative works of, publicly display, publicly perform, republish,
            download, store, transmit, or otherwise use any of the material on
            our Website or frame this Website within any other Website without
            our prior written permission. Systematic retrieval of data or other
            content from this site to create or compile, directly or indirectly,
            a collection, compilation, database or directory, without prior
            written permission from DBB Capital Ventures LLC, is prohibited.
            Trademarks The Company name, all registered trademarks, the Company
            logo, and all related names, logos, product and service names,
            designs, and slogans are trademarks of DBB Capital Ventures LLC or
            its afﬁliates or licensors. You must not use such marks without our
            prior written permission. All other names, logos, product and
            service names, designs, and slogans on this Website are the
            trademarks of their respective owners. Privacy policy The Privacy
            Policy can be accessed here, www.seguroamigo.app/privacy-policy •
            What personally identiﬁable information and/or other information is
            collected from you, how it is used and with whom it may be shared. •
            What choices are available to you regarding the use of your
            personally identiﬁable information. • The security procedures DBB
            Capital Ventures LLC has in place to protect your personally
            identiﬁable information. • How you can correct any inaccuracies in
            collected personally identiﬁable information. Accounts on
            www.seguroamigo.app Creating an Account. You have the option at your
            discretion to create an Account when using the Site
            (www.seguroamigo.app). Creating an Account at www.seguroamigo.app
            requires you to submit to use an email address and password that you
            as the user create. When you create an Account at
            www.seguroamigo.app, you are attesting that you are 16 years old or
            older at the time the Account is created and that the information
            you provide us is accurate, complete and current at all times. If
            you submit inaccurate, incomplete or not current information, DBB
            Capital Ventures LLC may, at DBB Capital Ventures LLC’s sole
            discretion, take any action with respect to your account that it
            deems appropriate under the circumstances, including, without
            limitation, immediate suspension or termination of your Account.
            Your Responsibility. You are responsible for maintaining the
            confidentiality of your Account, including but not limited to the
            restriction of access to your computer and/or Account, and
            protection of your password. You agree to accept responsibility for
            any and all activities or actions that occur under your Account
            and/or password. You must notify us immediately by sending an email
            to help@seguroamigo.app upon becoming aware of any breach of
            security or unauthorized use of your Account. In creating or using
            an Account you may not use as a username the name of another person
            or entity or that is not lawfully available for use, a name or
            trademark that is subject to any rights of another person or entity
            other than you, without appropriate authorization. You may not use
            as a username any name that is offensive, vulgar or obscene.
            Marketing Communications. By creating an Account at
            www.seguroamigo.app you agree that DBB Capital Ventures LLC can
            contact you with email or other communications relating to your
            Account and/or the Site. If you create an Account at
            www.seguroamigo.app and have not had or no longer have an insurance
            policy with DBB Capital Ventures LLC, you can contact us at
            help@seguroamigo.app to request that we remove you from our
            marketing distribution lists. We reserve the right to refuse
            service, terminate accounts, remove or edit content in DBB Capital
            Ventures LLC’s sole discretion. Links to other websites Our Site may
            contain links to third party websites or services that are not owned
            or controlled by DBB Capital Ventures LLC. DBB Capital Ventures LLC
            has no control over, and assumes no responsibility for the content,
            privacy policies, or practices of any third party websites or
            services. We do not warrant the offerings of any of these
            entities/individuals or their websites. You acknowledge and agree
            that DBB Capital Ventures LLC shall not be responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to
            be caused by or in connection with use of or reliance on any such
            content, goods or services available on or through any such third
            party websites or services. We strongly advise you to read the terms
            and conditions and privacy policies of any third party websites or
            services that you visit. Governing law In general, these Terms shall
            be governed and construed in accordance with the laws of Wyoming,
            United States, without regard to its conﬂict of law provisions.
            Notwithstanding the foregoing, all policies for motor vehicle
            insurance are governed by the laws in the state in which such
            policies are written. Speciﬁc enforcement of rights Our failure to
            enforce any right or provision of these Terms will not be considered
            a waiver of those rights. If any provision of these Terms is held to
            be invalid or unenforceable by a court, the remaining provisions of
            these Terms will remain in effect. These Terms constitute the entire
            agreement between us regarding our Site, and supersede and replace
            any prior agreements we might have had between us regarding the
            Site. Termination We may terminate or suspend your account and bar
            access to the Site immediately, without prior notice or liability,
            under our sole discretion, for any reason whatsoever and without
            limitation, including but not limited to a breach of the Terms.
            Please note that termination described in this section pertains to
            your use of www.seguroamigo.app and creating an account on that
            site. Termination or cancellation of an insurance policy with DBB
            Capital Ventures LLC is governed by the various state insurance laws
            and regulations. If you wish to terminate your use of the Site, you
            may simply discontinue using the Site. If you wish to disable your
            account, simply email us at help@seguroamigo.app. All provisions of
            the Terms which by their nature should survive termination shall
            survive termination, including, without limitation, ownership
            provisions, warranty disclaimers, indemnity and limitations of
            liability.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionPage;
