import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";

function LanguageDropDown({ setLanguage, selectedLanguage }) {
  const [isClick, setIsClick] = useState(false);
  const languageMenuRef = useRef(null);

  return (
    <div className="relative inline-block text-left ml-4 mr-1">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-5 py-[10px] text-[20px] font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          onClick={() => setIsClick(!isClick)}
        >
          {selectedLanguage === "en" ? (
            <span>
              <span className="text-red-700 font-bold">E</span>
              <span className="text-green-700  font-bold">S</span>
            </span>
          ) : (
            <span className="text-black uppercase">EN</span>
          )}
          {/* <svg
            className="-mr-1 ml-9 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg> */}
        </button>
      </div>
      {isClick && (
        <div
          ref={languageMenuRef}
          className="absolute right-0 z-10 mt-2 w-[100px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <button
              className="text-gray-700 block px-4 py-2 text-[18px]"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
              onClick={(e) => {
                e.preventDefault();
                setLanguage("en");
                setIsClick(false);
              }}
            >
              EN
            </button>
            <button
              className="text-gray-700 block px-4 py-2 text-[18px]"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-1"
              onClick={(e) => {
                e.preventDefault();
                setLanguage("es");
                setIsClick(false);
              }}
            >
              <span className="text-red-700">E</span>
              <span className="text-green-700">S</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageDropDown;
