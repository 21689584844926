import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { About } from "./about";
import { Trans, useTranslation } from "react-i18next";
import apple from "../assets/images/appy.png";
import google from "../assets/images/googy.png";
import ImageCarousel from "../components/ImageCarousel";
import heroImage from "../assets/images/hero.png";
import tick from "../assets/images/tick.png";
import { colors } from "@mui/material";
import './style1.css';
import './styles.css';
import jQuery from 'jquery';
import './home.css'
import LogoPng from "../assets/images/logo.png";


const checkpoints = [
  "home.points.first",
  "home.points.second",
  "home.points.third",
  "home.points.fourth",
];



export const Home = ({ setZipmodal }) => {

  useEffect(() => {
    // Dynamically load the external script
    const script = document.createElement('script');
    script.src = "https://msg.revspring.io/js/form_embed.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const { t, i18n } = useTranslation("global");
  const [navalue, setNavalue] = useState(false);
  // eslint-disable-next-line
  const [zipCode, setZipCode] = useState("");

  window.addEventListener("scroll", reveal);
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowheight = window.innerHeight;
      var revealtop = reveals[i].getBoundingClientRect().top;
      var revealpoint = 70;

      if (revealtop < windowheight - revealpoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const menuIcon = document.getElementById("menu-icon");
      const navbar = document.querySelector(".navbar");

      menuIcon.addEventListener("click", () => {
        if (!navalue) {
          navbar.style.display = "flex";
          setNavalue(true);
        } else {
          navbar.style.display = "none";
          setNavalue(false);
        }
      });
    });

    // initCobrowseIO();
    const intercomSettings = {
      app_id: "xwskn3vv",
    };
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/xwskn3vv";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
    (function (w, t, c, p, s, e) {
      p = new Promise(function (r) {
        w[c] = {
          client: function () {
            if (!s) {
              s = document.createElement(t);
              s.src = "https://js.cobrowse.io/CobrowseIO.js";
              s.async = 1;
              e = document.getElementsByTagName(t)[0];
              e.parentNode.insertBefore(s, e);
              s.onload = function () {
                r(w[c]);
              };
            }
            return p;
          },
        };
      });
    })(window, "script", "CobrowseIO");
  }, [navalue]);
  // eslint-disable-next-line

  const [email, setEmail] = useState('');
  const [emaildiabled, setemaildiabled] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting

    // alert(email)

    const email1 = email;

    if (email1 !== '') {

      setemaildiabled(true);
      document.getElementById('subscribe').style.backgroundColor = 'gray';

      jQuery.ajax({
        url: 'https://script.google.com/macros/s/AKfycbxe_HZsYoDWK1h_XGJYkl92sZYqzztbEOJgDLq0pPBlJQmdT_sbJuxVZr6rYXMisbD2dw/exec',
        // url: 'https://script.google.com/macros/s/AKfycbyUCmLsMK0rSABAQdmUgToZblWfAOHozL9QLv3SKZkx6viEzJwlDzwbL6LLn4WEdlv9/exec',
        type: 'post',
        data: { 'emailid': email1 }, // Use an object with a key 'zipcode'
        success: function (r) {
          // Handle success if needed
          // console.log("r = " + r);

          if (r === email1) {
            alert('Subscribed Successfully');
            setEmail('');
          }
        },

      });

    }

    else {
      alert('Please enter email id');
    }
  }



  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up event listener
  }, []);


  return (
    <div id="home">
      {/* HomeSection */}

      {/* <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="animate-charcter"> EKOSH</h3>
          </div>
        </div>
      </div> */}
      <section className="container px-4 my-12 mx-auto">


        {/* 
        {isMobile ?
          <div
            className="flex justify-center items-center pb-4 m-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <a
              href="https://quote.seguroamigo.app/"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              className="glow-on-hover3 text-black "
              data-lang="get-quote"
              onClick={(e) => {
                window.location.href = "https://quote.seguroamigo.app";
              }}
            >
              <Trans>navbar.agent</Trans>
            </a>

          </div> : ''
        } */}


        {/* {isMobile ?
          // <div className="flex justify-between gap-12 max-lg:items-center lg:flex-row flex-col">
          <div
            className="pb-4 m-3 items-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <a
              href="https://quote.seguroamigo.app/"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              className="glow-on-hover3 text-black"
              data-lang="get-quote"
              onClick={(e) => {
                window.location.href = "https://quote.seguroamigo.app";
              }}
            >
              <Trans>navbar.agent</Trans>
            </a>

          </div> : ''
        } */}

        

        <div className="topContainer flex">
          <p
            className="textInfo textInfo11 max-md:text-center md:mb-9 animate-charcter"
            data-lang="text-info"
          >
            <Trans>home.discountInsurance1</Trans>
            <Trans>home.discountInsurance2</Trans>
          </p>
          {/* <p className="animate-charcter">
            <Trans>home.discountInsurance1</Trans>
          </p> */}
          {/* <p
            className="textInfo textInfo11 max-md:text-center md:mb-9 animate-charcter"
            data-lang="text-info"
            style={{ color: "orange" }}
          >
            <Trans>home.discountInsurance2</Trans>
          </p> */}
        </div>





        <div className="flex justify-between gap-12 max-lg:items-center lg:flex-row flex-col">
          <div
            className="px-4 mt-9 md:w-max "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <a
              href="https://quote.seguroamigo.app/"
              // style="text-align: center; display: flex; justify-content: center; align-items: center;"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              className="glow-on-hover"
              data-lang="get-quote"
              onClick={(e) => {
                window.location.href = "https://quote.seguroamigo.app";
              }}
            >
              <Trans>home.button</Trans>
            </a>



            <div
              className="text-white  flex flex-col gap-2 mt-9 mb-5"
              data-lang="text-description"
            >
              {checkpoints.map((checkpoint, index) => (
                <div
                  key={checkpoint + "-" + index}
                  data-lang="text-point-4"
                  className="flex gap-2 md:items-center text-left"
                >
                  <img src={tick} className="w-8 h-8" alt="tick-4" />{" "}
                  <p>
                    <Trans>{checkpoint}</Trans>
                  </p>
                </div>
              ))}
            </div>

            <p
              className="text-xl md:text-2xl max-md:text-center text-white font-bold md:w-[600px] w-full mb-5"
              data-lang="text-title"
            >
              <Trans>home.p</Trans>
            </p>
            <p
              className="textInfo max-md:text-center md:mb-9"
              data-lang="text-info"
            >
              <Trans>home.h1</Trans>
            </p>
          </div>



          <div className="imgContainer flex">
            <img
              data-aos="fade-up"
              data-aos-duration="1000"
              width={400}
              className="max-w-full mt-auto"
              alt=""
              src={heroImage}
            />
          </div>
        </div>
      </section>

      {/* Download Section */}
      <div className="container  px-4 mx-auto">
        <div className=" idk"></div>
        <div className=" overlay ">
          <div
            className=" md:mainSection block mt-10"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {/* <img
              src="https://media.discordapp.net/attachments/1138016068359688203/1182022015687872562/mobile-removebg-preview.png?ex=65832ec9&is=6570b9c9&hm=0fba408aa493df9dc85a1a72ab55372bea2aa08ef83d1e5c64815b12fe54c088&=&format=webp&quality=lossless&width=669&height=663"
              alt="big-img"
              width={800}
              // style={{ visibility: "hidden" }}
            /> */}
          </div>
          <div className="w-full " data-aos="fade-up" data-aos-duration="1000">
            <div className="text-center"
              style={{ alignItems: 'center', textAlign: 'center', margin: '0 auto' }}
            >
              <h1 className="secondHeaderIg">
                <span className="shine" data-lang="our-app-title">
                  <Trans>home.shine</Trans>
                </span>
              </h1>
              <div className="shine indexTitle mx-auto" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <p data-lang="our-app-description">
                  <Trans>home.shine-p</Trans>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col md:text-5xl w-full justify-center grow-0 text-center text-white">
              <h2 className="gayitbutton md:text-6xl  oth-font">
                <Trans>footer.get</Trans>
              </h2>
              <div className=" mt-9 flex gap-5 justify-center items-center lg:flex-row flex-col ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=us.thebermuda.seguroamigo&pli=1"
                  className="googy"
                  title="Google Play"
                >
                  <img src={google} alt="google" className="max-w-80 w-full " />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/seguro-amigo/id6446518844?platform=iphone"
                  className="appy"
                  tabIndex="0"
                >
                  <img src={apple} alt="bn45" className="max-w-80 w-full " />
                </a>
              </div>
            </div>

            {/* 
            <div className="iframe-container">
              <iframe
                src="https://quotetruckinginsurance.com/"
                className="iframe-content"
                id="TxS7DGYhOPmPA2VyukTQ_1717127140746"
                title="External Widget"
              ></iframe>
            </div> */}


            <div
              className=" someText vectormanimg mainSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            ></div>
          </div>
        </div>
      </div>

      {/* About Section */}
      <div
        id="Dot"
        className="md:block hidden"
        style={{
          width: "1px",
          background: "transparent",
          height: "1px",
          position: "absolute",
          top: "178%",
        }}
      ></div>
      <About />

      {/* <section class="wrapper1">
        <div class="content1">
          <header>
            <h1>Subscribe Us</h1>
          </header>
          <section>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </section>
          <footer>
            <input type="email" placeholder="Enter your email" />
            <button>Let's go</button>
          </footer>
        </div>
      </section> */}

      {/* 
      <section class="flex justify-center items-center m-20 max-h-3xl">
        <div class="w-full flex flex-col justify-center items-center bg-white p-8 rounded-lg shadow-md">
          <header class="mb-4">
            <h1 class="my-10 md:text-5xl font-bold text-center">Subscribe Us</h1>
           
          </header>
          <section class="mb-4">
            <p class="text-gray-700 lg:text-xl text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </section>
          <footer class="flex w-full-1/2 lg:w-full-1/2 flex-col md:flex-row items-center justify-center gap-2">
            <input type="email" placeholder="Enter your email" className="w-full lg:max-w-4xl px-4 py-2 p-20 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500 mb-4 md:mb-0 md:mr-2" />
            <button className="w-full px-4 py-2 p-10 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none">Let's go</button>
          </footer>
        </div>
      </section> */}

      {/* <section class="wrapper1">
        <div class="content1 flex justify-center items-center min-h-screen">
          <header>
            <h1 class="text-3xl font-bold text-center">Subscribe Us</h1>
          </header>
          <section
>
            <p class="text-center px-4 py-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </section>
          <footer class="flex justify-center gap-2">
            <input type="email" class="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-500" placeholder="Enter your email" />
            <button class="bg-sky-500 text-white px-4 py-2 rounded-md hover:bg-sky-700">Let's go</button>
          </footer>
        </div>
      </section> */}










      <div class="hero">
        <div class="hero-inner">

          <div class="hero-text">
            <h1 class="text-6xl font-bold text-center mb-5">Subscribe Us</h1>

            <form class="hero-form" onSubmit={handleEmailSubmit}>
              <p className="pt-5 pb-5 text-lg text-black">Explore the latest trending topics and stay informed about the hottest discussions!</p>

              <div class="hero-form-input flex gap-2">
                <input
                  className="hero-email-input px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-500"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  disabled={emaildiabled}
                  onChange={handleEmailChange}
                  required
                />
                <input
                  type="submit"
                  value="Subscribe"
                  id="subscribe"
                  className="hero-form-submit text-white px-4 py-2 rounded-md hover:bg-sky-700 cursor-pointer animate__animated animate__pulse animate__infinite"
                />
              </div>
            </form>
          </div>

          <div class="hero-image">
            <img src="https://assets.codepen.io/495197/undraw_Newsletter_re_wrob.svg" />
          </div>

        </div>
      </div>




    </div >
  );
};
