import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import apple from "../assets/images/appy.png";
import google from "../assets/images/googy.png";
import facebook from "../assets/images/facebook.png";
import linkedin from "../assets/images/linkedin.png";
import instagram from "../assets/images/instagram.png";
import twitter from "../assets/images/twitter.png";
import tiktok from "../assets/images/tiktok.png";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import LogoPng from "../assets/images/logo.png";

export const Footer = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <footer className="py-8 ">
      <div className="container px-4 mx-auto flex gap-14 max-md:flex-wrap justify-center">
        <div className=" flex flex-col gap-4 md:gap-6 shrink-0 ">
          <img src={LogoPng} alt="SeguroAmigoLogo" className="w-60" />
          <div className="flex  flex-row gap-3 ml-3">
            <Link to={"https://www.facebook.com/110672505264432"}>
              <img className="w-8 h-auto" alt="facebook" src={facebook} />
            </Link>
            <Link to={"https://www.linkedin.com/organization/90435721"}>
              <img className="w-8 h-auto" alt="linkedin" src={linkedin} />
            </Link>
            <Link to={"https://www.tiktok.com/seguroamigo_app"}>
              <img className="w-8 h-auto" alt="tiktok" src={tiktok} />
            </Link>
            <Link to={"https://www.instagram.com/seguroamigo_app"}>
              <img className="w-8 h-auto" alt="instagram" src={instagram} />
            </Link>
            <Link to={"https://www.twitter.com/seguroamigo_app"}>
              <img className="w-8 h-auto" alt="twitter" src={twitter} />
            </Link>
          </div>
        </div>
        <div className="text-white w-full text-xl flex flex-col gap-6 text-nowrap px-6 max-md:text-center ">
          <Link to="/" className="hover:text-[#f9a602] transition">
            <Trans>navbar.home</Trans>
          </Link>



          <Link to="/file-a-claim" className="hover:text-[#f9a602] transition">
            <Trans>navbar.fileAClaim</Trans>
          </Link>

          <Link
            to="terms-and-conditions"
            // target="_blank"
            // rel="noreferrer"
            // href="https://cdn.discordapp.com/attachments/1166222269027209276/1190165181658046474/Terms_and_Conditions.docx?ex=65a0ceb3&is=658e59b3&hm=dbb64c44552a473035f6e3d52f51f7461bdab81759068603c8f2f99669a77f12&"
            className=" md:block hover:text-[#f9a602] transition"
          >
            <Trans>navbar.terms</Trans>
          </Link>

          <Link
            // target="_blank"
            // rel="noreferrer"
            // href="https://cdn.discordapp.com/attachments/1166222269027209276/1190165181356068894/Privacy_Policy_Updated.docx?ex=65a0ceb3&is=658e59b3&hm=248a37075fe9d3b1391c8c957e4892ae08e9f6400faf4682c95040a797fe1049&"
            to="privacy-policy"
            className=" md:block hover:text-[#f9a602] transition"
          >
            <Trans>Privacy Policy</Trans>
          </Link>

          <Link
            to="/terms-and-conditions"
            className="hover:text-[#f9a602] transition"
          >
            <Trans>navbar.about</Trans>
          </Link>

          <Link
            to="/blogs-and-articles"
            className="hover:text-[#f9a602] transition"
          >
            <Trans>navbar.blog</Trans>
          </Link>
        </div>

        <div className="flex flex-col md:text-5xl w-full justify-center grow-0 text-center text-white">
          <h2 className="gayitbutton md:text-6xl  oth-font">
            <Trans>footer.get</Trans>
          </h2>
          <div className=" mt-9 flex gap-5 justify-center items-center lg:flex-row flex-col ">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=us.thebermuda.seguroamigo&pli=1"
              className="googy"
              title="Google Play"
            >
              <img src={google} alt="google" className="max-w-80 w-full " />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/seguro-amigo/id6446518844?platform=iphone"
              className="appy"
              tabIndex="0"
            >
              <img src={apple} alt="bn45" className="max-w-80 w-full " />
            </a>
          </div>
        </div>
      </div>
      <p className="copyrightText"><span className="yellowText">SEGURO AMIGO APP</span> is owned and operated by <span className="yellowText">DBB CAPITAL VENTURES LLC</span></p>
      <p
        className="copyrightText">
        <a href="https://msg.revspring.io/widget/bookings/insurtech"
        >
          <Trans>navbar.investors</Trans>
        </a>
      </p>
      <p className="copyright">Info@seguroamigo</p>
    </footer>
  );
};
