import React, { useState, useEffect } from "react";
import NavLogo from "../assets/images/nav_logo.png";
import NavLogoEs from "../assets/images/nav_logo_es.png";
import SpinnerLogo from "../assets/images/nav_logo_spinner.png";
import FastBusiness from "../assets/images/fast business.png";
import ImmigrationLegal from "../assets/images/immigrationlegal.png";
import NavLogoEs1 from "../assets/images/nav_logo_es1.jpg";
import FastBusinessEs from "../assets/images/fast business_es.png";
import ImmigrationLegalEs from "../assets/images/immigrationlegal_es.png";
import Nav2 from "../assets/images/nav1.png";
import Nav1 from "../assets/images/Nav11.png";

import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";


// const zipCode = localStorage.getItem("zip");
function SubBar({ zipmodal, language }) {


  const [link, setLink] = useState('');

  // Fetch initial link from localStorage (optional)
  useEffect(() => {
    const storedLink = localStorage.getItem('link');
    if (storedLink) {
      setLink(storedLink);
    }
  }, []);

  const handleClick = () => {
    // const storedLink = localStorage.getItem('link');
    const storedLink = "http://kgwlawfirm.com/personal-injury-attorney-dallas/";
    if (storedLink) {
      handleNavigateWithDelay(storedLink);
    } else {
      // Fallback logic if no link is found (e.g., display message or navigate elsewhere)
      handleNavigateWithDelay("/");

    }
  };


  function handleNavigate(value) {
    if (value === "/") {
      window.location.href = value;
    } else {
      window.open(value);
    }
  }


  const [zipCode, setZipCode] = useState("");
  useEffect(() => {
    setZipCode(localStorage.getItem("zipCode"));
  }, [zipmodal]);

  // function handleNavigateWithDelay(url) {
  //   if (url !== "/") {
  //     setTimeout(() => {
  //       // window.location.href = url;
  //       // alert("handleClickIMG1" + url);
  //       window.location.href = url.includes('http') ? url : 'http://' + url;
  //       // window.open(url.includes('http') ? url : 'http://' + url, '_blank');
  //       // window.open(url.includes('http') ? url : 'http://' + url);
  //     }, 500);
  //   }

  // }


  function handleNavigateWithDelay(url) {
    if (url !== "/") {
      setTimeout(() => {
        // Check if the URL starts with http or https
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url;
        }
        window.location.href = url; // Navigate to the URL
      }, 100);
    }
  }







  // const handleClickIMG1 = () => {
  //   const storedLink = localStorage.getItem('link2');
  //   if (storedLink) {
  //     // handleNavigateWithDelay(storedLink);

  //     window.location.href = storedLink.includes('http') ? storedLink : 'http://' + storedLink;

  //   } else {
  //     // Fallback logic if no link is found (e.g., display message or navigate elsewhere)
  //     // handleNavigateWithDelay("/");

  //   }
  // };


  const handleClickIMG1 = () => {
    // alert("handleClickIMG");
    // const storedLink = localStorage.getItem('link2');
    const storedLink = "http://kgwlawfirm.com/immigration-attorney-dallas/";
    if (storedLink) {
      handleNavigateWithDelay(storedLink);
    } else {
      // Fallback logic if no link is found (e.g., display message or navigate elsewhere)
      handleNavigateWithDelay("/");

    }
  };


  return (
    <>
      <div className="links-section hidden md:flex mb-3 flex-col md:flex-row justify-center md:justify-between w-full items-center md:h-[100px] bg-[#222327] text-white px-4 py-2">
        <div className="md:flex md:text-sm md:flex-row md:justify-space-around items-center justify-between text-center w-full lg:px-10">
          <Link>
            <div className="flex flex-row">
              <header>
                <li className="dropdown ">
                  <ul className="main-navigation ">
                    <li className="menu-item ">
                      <div className={`flex flex-row w-[300px]`}>
                        {language === "en" ? (
                          <>
                            <img
                              src={SpinnerLogo}
                              alt="logo"
                              className="w-16 h-16"
                            />
                            <img
                              src={NavLogo}
                              alt="logo"
                              className="w-30 h-16 "
                            />
                          </>
                        ) : (

                          // <img
                          //   // src={NavLogoEs}
                          //   src={Nav1}
                          //   alt="logo"
                          //   className="w-30 h-17 hover:bg-black"
                          // />
                          <a
                            target="_blank"
                            rel="noreferrer"
                            id="websideimmgigration1"
                            className="flex items-center gap-3 hover:bg-gray-500"
                          >
                            <p
                              className="text-white sm:text-xl md:text-3xl lg:text-4xl font-bold hover:bg-gray-500"
                            >
                              {/* <Trans
                                  className="hover:bg-gray-500"
                                >navbar.legalservices</Trans> */}
                              Community Support
                            </p>
                          </a>
                        )}
                      </div>

                      <ul className="sub-menu pt-5 ">
                        <li className="menu-item w-[600px]">
                          <Link to="/" data-lang="point1">
                            {" "}
                            <Trans>navbar.dropdown.firstdrop.title</Trans>
                          </Link>
                          <ul className="sub-menu hmm">




                            <li className="menu-item">
                              <p
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white "
                                target="_blank"
                                // onClick={(e) => {
                                //   //open in new tab
                                //   e.preventDefault(); // Prevent default behavior
                                //   window.open("https://www.ajimmigration.com");
                                // }}

                                onClick={(e) => {
                                  handleClickIMG1();

                                  e.preventDefault(); // Prevent default behavior
                                }}
                                rel="noreferrer"
                                // href="https://www.ajimmigration.com/"
                                data-lang="subpoint11"
                              >
                                <Trans>navbar.dropdown.firstdrop.firstt</Trans>
                              </p>
                            </li>

                            <li className="menu-item">
                              <p
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white "
                                target="_blank"
                                onClick={(e) => {
                                  //open in new tab
                                  e.preventDefault(); // Prevent default behavior
                                  window.open("https://www.ajimmigration.com");
                                }}
                                rel="noreferrer"
                                href="https://www.ajimmigration.com/"
                                data-lang="subpoint11"
                              >
                                <Trans>navbar.dropdown.firstdrop.first</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // herf="https://www.immigrationforum.org/"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // onClick={(e) => {
                                //   e.preventDefault();
                                //   window.open("https://www.immigrationforum.org/");
                                // }}

                                onClick={(e) => {
                                  e.preventDefault(); // Prevent default browser behavior (link opening in new tab)
                                  window.location.href = "https://www.immigrationforum.org/"; // Open in current tab
                                }}
                                data-lang="subpoint12"
                              >
                                <Trans>navbar.dropdown.firstdrop.second</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.americanprogress.org/article/providing-identification-to-unauthorized-immigrants/";
                                }}
                                data-lang="subpoint13"
                              >
                                <Trans>navbar.dropdown.firstdrop.third</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://ilrc.org";
                                }}
                                data-lang="subpoint14"
                              >
                                <Trans>navbar.dropdown.firstdrop.fourth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://frac.org/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://frac.org/";
                                }}
                                data-lang="subpoint15"
                              >
                                <Trans>navbar.dropdown.firstdrop.fifth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.nilc.org/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.nilc.org/";
                                }}
                                data-lang="subpoint16"
                              >
                                <Trans>navbar.dropdown.firstdrop.sixth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.informedimmigrant.com/resources/mental-health/mental-health-undocumented-immigrants/";
                                }}
                                data-lang="subpoint16"
                              >
                                <Trans>navbar.dropdown.firstdrop.seventh</Trans>
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item w-[600px]">
                          <Link to="/" data-lang="point2">
                            <Trans>navbar.dropdown.seconddrop.title</Trans>
                          </Link>
                          <ul className="sub-menu hmm">
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://unitedwedream.org";
                                }}
                                // href="https://unitedwedream.org"
                                data-lang="subpoint21"
                              >
                                <Trans>navbar.dropdown.seconddrop.first</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.ushcc.com/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.ushcc.com/";
                                }}
                                data-lang="subpoint22"
                              >
                                <Trans>navbar.dropdown.seconddrop.second</Trans>{" "}
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://chci.org"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://chci.org";
                                }}
                                data-lang="subpoint23"
                              >
                                <Trans>navbar.dropdown.seconddrop.third</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.laycdc.org"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.laycdc.org";
                                }}
                                data-lang="subpoint24"
                              >
                                <Trans>navbar.dropdown.seconddrop.fourth</Trans>{" "}
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="http://www.lcdanm.org"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "http://www.lcdanm.org";
                                }}
                                data-lang="subpoint25"
                              >
                                <Trans>navbar.dropdown.seconddrop.fifth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.maof.org"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.maof.org";
                                }}
                                data-lang="subpoint26"
                              >
                                <Trans>navbar.dropdown.seconddrop.sixth</Trans>
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item w-[600px]">
                          <Link to="/" data-lang="point3">
                            <Trans>navbar.dropdown.thirddrop.title</Trans>
                          </Link>
                          <ul className="sub-menu hmm">
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.uscis.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.uscis.gov";
                                }}
                                data-lang="subpoint31"
                              >
                                <Trans>navbar.dropdown.thirddrop.first</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.acf.hhs.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.acf.hhs.gov";
                                }}
                                data-lang="subpoint32"
                              >
                                <Trans>navbar.dropdown.thirddrop.second</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.justice.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.justice.gov";
                                }}
                                data-lang="subpoint33"
                              >
                                <Trans>navbar.dropdown.thirddrop.third</Trans>{" "}
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.hhs.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.hhs.gov";
                                }}
                                data-lang="subpoint34"
                              >
                                <Trans>navbar.dropdown.thirddrop.fourth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.eeoc.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.eeoc.gov";
                                }}
                                data-lang="subpoint35"
                              >
                                <Trans>navbar.dropdown.thirddrop.fifth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.ed.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.ed.gov";
                                }}
                                data-lang="subpoint36"
                              >
                                <Trans>navbar.dropdown.thirddrop.sixth</Trans>
                              </p>
                            </li>
                            <li className="menu-item">
                              <p
                                // target="_blank"
                                className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[700px]"
                                rel="noreferrer"
                                // href="https://www.hud.gov"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = "https://www.hud.gov";
                                }}
                                data-lang="subpoint37"
                              >
                                <Trans>navbar.dropdown.thirddrop.seventh</Trans>
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <p
                            className="bg-[#f9a602] py-2 rounded-md hover:bg-[#2c3e50] transition hover:text-white w-[580px]"
                            target="_blank"
                            rel="noreferrer"
                            onClick={handleClick}
                            // onClick={(e) => {

                            //   handleNavigateWithDelay("https://prayag.com/");
                            //   handleNavigateWithDelay(
                            //     parseInt(zipCode) >= 73002 &&
                            //       parseInt(zipCode) <= 74966
                            //       ? "https://parrishdevaughn.com/"
                            //       : parseInt(zipCode) >= 75001 &&
                            //         parseInt(zipCode) <= 79942
                            //       ? "https://wsfirm.com/practice-areas/personal-injury/car-accidents/"
                            //       : "/"
                            //   );
                            //   e.preventDefault(); // Prevent default behavior
                            // }}
                            data-lang="point4"
                          >
                            <Trans>navbar.dropdown.fourthdrop</Trans>
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </header>
            </div>
          </Link>

          <Link to={"/coverage"}>
            {/* <img
              // src={language === "en" ? FastBusiness : FastBusinessEs}
              src={language === "en" ? Nav2 : Nav2}
              alt="fast business logo"
              className="w-30 h-16 hover:bg-black"
            /> */}

            <a
              target="_blank"
              rel="noreferrer"
              id="websideimmgigration1"


              className="flex items-center gap-3 hover:bg-gray-500"
            >
              <p
                className="text-white sm:text-xl md:text-3xl lg:text-4xl font-bold hover:bg-gray-500"

              >
                {/* <Trans
                                  className="hover:bg-gray-500"
                                >navbar.legalservices</Trans> */}
                Secure Business Coverage
              </p>
            </a>

          </Link>

          <Link to={"https://live-states.vercel.app/"}>
            {/* <img
              // src={language === "en" ? FastBusiness : FastBusinessEs}
              src={language === "en" ? Nav2 : Nav2}
              alt="fast business logo"
              className="w-30 h-16 hover:bg-black"
            /> */}

            <a
              target="_blank"
              rel="noreferrer"
              id="websideimmgigration1"


              className="flex items-center gap-3 hover:bg-gray-500"
            >
              <p
                className="text-white sm:text-xl md:text-3xl lg:text-4xl font-bold hover:bg-gray-500"

              >
                {/* <Trans
                                  className="hover:bg-gray-500"
                                >navbar.legalservices</Trans> */}
                Live State
              </p>
            </a>

          </Link>

          {language === "en" ? (
            <>
              <p
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  handleClickIMG1();

                  e.preventDefault(); // Prevent default behavior
                }}
                className="hover:bg-gray-500"

              >
                {/* <img
                  src={ImmigrationLegal}
                  id="websiteImmigration"
                  alt="Immigration legal status"
                  onClick={(e) => {
                    handleClickIMG1();
                   
                    e.preventDefault(); // Prevent default behavior
                  }}
                  className="w-30 h-16 hover:bg-gray-500"
                /> */}
              </p>
            </>
          ) : (
            <>
              <p
                target="_blank"
                rel="noreferrer"
                className="hover:cursor-pointerhover:bg-gray-500"

                onClick={(e) => {
                  handleClickIMG1();
                  // alert("hi am here SUBNAVBAR");
                  e.preventDefault(); // Prevent default behavior
                }}
              >
                {/* <img
                  alt="Immigration legal status es hover:cursor-pointer"
                  src={ImmigrationLegalEs}
                  onClick={(e) => {
                    handleClickIMG1();
                    // alert("hi am here SUBNAVBAR");
                    e.preventDefault(); // Prevent default behavior
                  }}
                  className="w-30 h-17 hover:bg-gray-500"
                /> */}
              </p>
            </>
          )}
        </div >
      </div >
    </>
  );
}
export default SubBar;
