export const cardDetails = {
  0: {
    imgUrl: "/CaroulseImages/1.png",
    title: "Text 1",
  },

  1: {
    imgUrl: "/CaroulseImages/2.png",
    title: "Text 2",
  },
  2: {
    imgUrl: "/CaroulseImages/3.png",
    title: "Text 3",
  },
  3: {
    imgUrl: "/CaroulseImages/4.png",
    title: "Text 4",
  },
  4: {
    imgUrl: "/CaroulseImages/5.png",
    title: "Text 5",
  },
  5: {
    imgUrl: "/CaroulseImages/6.png",
    title: "Text 6",
  },
  6: {
    imgUrl: "/CaroulseImages/7.png",
    title: "Text 7",
  },
  7: {
    imgUrl: "/CaroulseImages/8.png",
    title: "Text 8",
  },
  8: {
    imgUrl: "/CaroulseImages/9.png",
    title: "Text 9",
  },
  9: {
    imgUrl: "/CaroulseImages/10.png",
    title: "Text 10",
  },
  10: {
    imgUrl: "/CaroulseImages/11.png",
    title: "Text 11",
  },
  11: {
    imgUrl: "/CaroulseImages/12.png",
    title: "Text 12",
  },
  12: {
    imgUrl: "/CaroulseImages/13.png",
    title: "Text 13",
  },
  13: {
    imgUrl: "/CaroulseImages/14.png",
    title: "Text 14",
  },
  14: {
    imgUrl: "/CaroulseImages/15.png",
    title: "Text 15",
  },
  15: {
    imgUrl: "/CaroulseImages/16.png",
    title: "Text 16",
  },
  16: {
    imgUrl: "/CaroulseImages/17.png",
    title: "Text 17",
  },
  17: {
    imgUrl: "/CaroulseImages/18.png",
    title: "Text 18",
  },
  18: {
    imgUrl: "/CaroulseImages/19.png",
    title: "Text 19",
  },
  19: {
    imgUrl: "/CaroulseImages/20.jpg",
    title: "Text 20",
  },
  20: {
    imgUrl: "/CaroulseImages/21.jpg",
    title: "Text 21",
  }

};
