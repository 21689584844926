// import React from 'react'
// import './styles.css'
// import { seguroData } from '../assets/seguroData'

// export const fileAClaim = () => {
//   return (
//     <div id="Contact">
//       <div className="">
//         <div className="imagething">
//           {seguroData.map((data, index) => (
//             <div className="wrapper" key={index}>
//               <img src={data.icon} alt="" className='' />
//               <div className='bg-[url(assets/images/card-bg.png)] bg-cover w-full p-16 flex flex-col justify-center items-center'>
//                 <h2 className='font-bold text-9xl'>{`Claim: ${data.claim}`}</h2>
//                 <h2 className='font-bold'>{data.header}</h2>
//                 <div className="link">
//                   <a className="links" href={data.link} onClick={(e) => {
//                     window.location.href = data.link;
//                   }}>Click here</a>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   )
// }

import React from "react";
import "./styles.css";
import { seguroData } from "../assets/seguroData";

const FileAClaim = () => {
  return (
    <div id="Contact">
      <div className="">
        <div className="imagething">
          {seguroData.map((data, index) => (
            <div className="wrapper" key={index}>
              <img src={data.icon} alt="" className="" />
              <div className="bg-[url(assets/images/card-bg.png)] bg-cover w-full p-16 flex flex-col justify-center items-center">
                <h2 className="font-bold text-9xl">{`${data.claim}`}</h2>
                <h2 className="font-bold">{data.header}</h2>
                <div className="link">
                  <a
                    className="links"
                    href={data.link}
                    onClick={(e) => {
                      window.location.href = data.link;
                    }}
                  >
                    Click here
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileAClaim;
