import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { Trans, useTranslation } from "react-i18next";
export const Mission = ({ isPage = false }) => {
  const sectionRef = useRef(null);
  // eslint-disable-next-line
  const { t, i18n } = useTranslation("global");
  const [showMore, setShowMore] = useState(false);

  const toggleReadMore = async () => {
    setShowMore(!showMore);
  };

  // const morePara = <Trans>home.paragraphs1.p3</Trans>;

  // Apoyo Empresarial con Seguros Comerciales: No solo contamos con Seguros para autos, Seguro Amigo
  // App ofrece opciones completas para seguros comerciales. Sabemos de primera mano que muchos
  // inmigrantes que llegan a América también son exitosos propietarios de pequeñas empresas.
  // Entendemos los diferentes matices de iniciar y administrar un negocio, nuestra plataforma está
  // diseñada para brindar la protección que tu empresa merece. Continuaremos agregando más valor a esta
  // plataforma en cada oportunidad que tengamos que hacerlo. También escucharemos las necesidades de
  // nuestros clientes. Si no tenemos lo que TÚ necesitas, haremos todo lo posible por traerlo aquí y
  // brindártelo.
  // Más que Solo Seguros: Más allá de encontrar la póliza correcta, Seguro Amigo App sirve como un centro
  // de recursos. Proporcionamos enlaces e información valiosa para ayudarte en varios aspectos de tu vida,
  // desde asesoría legal hasta planificación financiera. Nuestra misión es hacer de esta plataforma una
  // comunidad que te ofrezca tanto apoyo como sea posible. Continuaremos agregando valor cada día.
  // Con Seguro Amigo App, eliges un AMIGO comprometido a proporcionarte las mejores soluciones de
  // seguros. Te invitamos a unirte a nuestra plataforma y experimentar un enfoque sin complicaciones para
  // encontrar el mejor seguro para tu auto.

  useEffect(() => {
    executeScroll();
    // window.addEventListener("scroll", reveal);
    // return () => {
    //   window.removeEventListener("scroll", reveal);
    // };
  }, []);
  const executeScroll = () => {
    isPage && sectionRef.current.scrollIntoView();
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowheight = window.innerHeight;
      var revealtop = reveals[i].getBoundingClientRect().top;
      var revealpoint = 110;

      if (revealtop < windowheight - revealpoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  return (
    <div
      ref={sectionRef}
      className={`aboutme  bg-[url('assets/images/bg-about-us.png')] md:bg-cover bg-repeat flex md:justify-center items-center
      ${showMore ? "md:h-[1200px] md:bg-none bg-[#ffa800] mt-0" : "h-full"}
      `}
      id="about"
    >
      <div className="text-[22px] font-bold mt-5 container mx-auto">
        <div
          className="flex md:w-full flex-col  justify-center text-[#202020] items-center   mainSection_"
          data-lang="aboutcontent"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1
            className="  my-10 md:text-7xl text-4xl max-md:text-center"
            data-lang="aboutheader"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Trans>home.paragraphs1.title</Trans>
          </h1>
          <p className="text-pretty text-md font-medium tracking-wide">
            <Trans>home.paragraphs1.p1</Trans>
            <span className="h-6 block w-full" />
          </p>

          {/* <button
            className="text-black text-2xl p-3 px-4 mt-6 rounded-3xl border-4 border-black "
            onClick={toggleReadMore}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {showMore ? "Read Less" : "Read More"}
          </button> */}
        </div>
      </div>
    </div>
  );
};
